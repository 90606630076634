import cn from 'classnames';
import css from '../styles/header.module.css';

interface HeaderProps {
  className: string;
  children?: React.ReactNode;
  id?: string;
}

export default function Header({ id, children, className }: HeaderProps) {
  return (<h1 id={id} className={cn(css.header, className)}>{children}</h1>);
}