import { useTranslation } from 'react-i18next';
import css from '../styles/cookie.module.css';
import { Button } from '../../select-items/components/Button';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';



export default function Cookie(){
  const { i18n } = useTranslation();

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const accept = localStorage.getItem('COOKIE_ACCEPT');
    if (!accept) {
      setDisplay(true);
    }
  }, []);


  const onAccept = () => {
    setDisplay(false);
    localStorage.setItem('COOKIE_ACCEPT', 'true');
  }


  const lang = i18n.language === 'ru' ? 'ru' : 'en';


  return (<div className={cn(css.cookie, { [css.cookie__display]: display })}>
    <div className={css.cookie__content}>
      <div className={css.expla}><TranslateWrap>welcome:cookies-info</TranslateWrap></div>
      <a href={`/docs/privacy_policy_${lang}.html`} className={css.isok}><TranslateWrap>welcome:cookies-ok</TranslateWrap></a>
      <Button preset="yellow" onClick={onAccept}><TranslateWrap>welcome:accept</TranslateWrap></Button>
    </div>
  </div>)
}