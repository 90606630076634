import { ReactHTMLElement, ReactNode, useCallback, useContext } from "react";
import cn from 'classnames';
import { AdminContext } from "../context/AdminContext";
import css from '../styles/ImageChangeWrap.module.css';
import { Form } from "@web-products/ui-kit";
import { useUpdateImageDesignMutation } from "../api/designimg-item-api";

interface ImageChangeWrapProps {
  id: string,
  children: ReactNode,
  className?: string,
  lang: string,
}

export default function ImageChangeWrap({ id, children, className, lang }: ImageChangeWrapProps) {
  const { isAdmin  } = useContext(AdminContext);

  const [updateImage] = useUpdateImageDesignMutation();

  const onClickImg = useCallback((file: File) => {
    updateImage({
      id,
      lang,
      file,
    })
    // return (<img {...props} className={cn(className)} />)
  }, [id, lang, updateImage]);

  if (isAdmin) {
    const props = (children as ReactHTMLElement<HTMLImageElement>)?.props;


    return (<Form.File.FileUploadCustom 
      isUploaded={false}
      progress={0}
      className={cn(css.imageChangeWrap, props.className)}
      onChange={onClickImg}
    >
      {({ selectedFile, selectedFileList, onChangeFile }) => (
        <Form.File.FileInputWrap
          className={css.imageChangeWrap__label}
          id={`image-id=${id}`}
          locales={{}}
          selectedFile={selectedFile}
          selectedFileList={selectedFileList}
          onChangeFile={onChangeFile} 
          Button={() => (
            <img {...props} className={cn(className)} /> 
          )}
        />
      )}
 
    </Form.File.FileUploadCustom>);
  }

  return <>{children}</>;
}