import { useRef } from "react";
import { useSelector } from "react-redux";
import { layersSelectors } from "../editor/store/layers";
// import imageRenderToCanvas from "./utils/imageRenderToCanvas";
// import { downloadImage } from "./utils/downloadImage";
// import shareImage from "./utils/shareImage";
import { Button } from "../select-items/components/Button";
import dataURLtoFile from "./utils/dataUrlToFile";
import useBakeImg from "./utils/useBakeImg";
// import imageUrlToBase64 from "./utils/imageUrlToBase64";
import css from './styles/bake.module.css';
import TranslateWrap from "../admin-actions/components/TranslateWrap";

interface BakeProps {
  svg: SVGSVGElement | null,
  onUploadResult: (file: File) => void
  // size: { width: number, height: number },
}

export default function Bake({ svg, onUploadResult }: BakeProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const layers = useSelector(layersSelectors.selectAll);

  const { bake } = useBakeImg(svg!, layers, canvasRef.current!);


  const onUplaoad = async () => {
    await bake();
    const base64Str = canvasRef.current?.toDataURL("image/jpeg", 0.7);
    if (base64Str) {
      const file = dataURLtoFile(base64Str, 'gitcard.jpeg');
      console.log(file)
      if (file) {
        onUploadResult(file);
      }
    }
  }

  return (<div className={css.bake}>
    <div>
      {/* <button onClick={shareImage}>Share</button>
      <button onClick={onDownload}>Download</button>
      <button onClick={bake}>SAVEEEE</button> */}
      <Button preset="yellow" onClick={onUplaoad}><TranslateWrap>editor:share-friends</TranslateWrap></Button>
    </div>
    {/* {data && <img src={data?.cdnUrl} alt="result" />} */}
    <canvas
      style={{
        width: '1px',
        height: '1px',
      }}
      ref={canvasRef}
    />
  </div>)
}