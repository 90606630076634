import { configureStore } from '@reduxjs/toolkit';
import { layerSlice } from '../modules/editor/store/layers';
import selectedSlice from '../modules/editor/store/selected';
import editModeSlice from '../modules/editor/store/edit-mode';
import { emptySplitApi } from './api';

export const store = configureStore({
  reducer: {
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    layers: layerSlice.reducer,
    selected: selectedSlice.reducer,
    editMode: editModeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(emptySplitApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch