import { Form } from "@web-products/ui-kit";
import { DataItem } from '../../editor/types';
import css from '../styles/select-bg.module.css';
import { ContentItem } from '../type';

import logoImg from '../assets/sologo.png';
import { BgData } from '../../editor/api/types';
import Tape from '../../layouts/components/Tape';
import { YM_EVENTS, ymEvent } from '../../metrica/ym';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';
import { useContext, useState } from 'react';
import { AdminContext } from '../../admin-actions/context/AdminContext';
import UploadItemToList from '../../admin-actions/components/uploadItemToList';
import EditableItemWrap from '../../admin-actions/components/editableItemWrap';
import useNextAndPrevOrder from '../../admin-actions/selector/nextAndPrevOrderItem';

interface SelectBgProps {
  onSelect: (id: DataItem, scrollTo?: string) => void;
  items: BgData[],
}

export default function SelectBg({ onSelect, items }: SelectBgProps) {
  const { isAdmin  } = useContext(AdminContext);

  const onAdd = (item: ContentItem) => {
    ymEvent(YM_EVENTS.EDITOR_CHANGE_BG);
  
    onSelect({
      id: `bg`,
      src: item.src,
      order: 2000,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      rotate: 0,
      isDragable: false,
      isFullSizeBg: true,
      photoPlaceholder: item.photoPlaceholder,
      unDelete: true,
    });

    onSelect({
      id: `logo`,
      src: logoImg,
      order: 200001,
      width: 176,
      height: 34,
      x: 30,
      y: 30,
      rotate: 0,
      isDragable: true,
      stayInViewport: true,
      minimumSize: 50,
      unDelete: true,
      layerType: 'logo',
    }, 'editor_start');
  }


  const bgs: ContentItem[] = [...items].map(bg => (
    {
      id: bg.id,
      src: bg.cdnUrl,
      thumbnailUrl: bg.thumbnailUrl,
      photoPlaceholder: bg.photoPlaceholder,
      order: bg.order,
    }
  ))

  const { maxOrder, nextAndPrevOrder } = useNextAndPrevOrder(bgs);

  // const nextAndPrevOrder = useMemo(() => {
  //   return bgs.reduce((acc, bg, key, items) => {
  //     acc[bg.id] = { 
  //       prev: items[key - 1]?.order,
  //       next: items[key + 1]?.order,
  //       prevId: items[key - 1]?.id,
  //       nextId: items[key + 1]?.id,
  //     };
  //     return acc; 
  //   }, {} as Record<string, { prev?: number, next?: number, prevId?: string, nextId?: string } >);
  // }, [bgs]);

  const [photoPlaceholder, setPhotoPlaceholder] = useState<boolean>(false);


  return (<div className={css.selectBg}>
    <h2 id="select-bg" className={css.header}><TranslateWrap>select-items:header</TranslateWrap></h2>
    {isAdmin && (<UploadItemToList extraParams={{ photoPlaceholder }} templateType="background" maxOrder={maxOrder} />)}
    {isAdmin && (<Form.Checkbox onChange={() => setPhotoPlaceholder(!photoPlaceholder)}><h3>С фото</h3></Form.Checkbox>)}
    <div className={css.list}>
      {bgs.map(bg => (
        <EditableItemWrap 
          nextOrder={nextAndPrevOrder[bg.id].next}
          backOrder={nextAndPrevOrder[bg.id].prev}
          nextId={nextAndPrevOrder[bg.id].nextId}
          prevId={nextAndPrevOrder[bg.id].prevId}
          className={css.imgItem}
          key={bg.id}
          item={bg}
          templateType="background"
        > 
          <button
            onClick={() => onAdd(bg)}
            className={css.imgItem}
          >
            <img className={css.imgItem__img}  src={bg.thumbnailUrl} alt={"bg"} />
          </button>
      </EditableItemWrap>
    ))}
    </div>
    <Tape />
  </div>);
}

// return (<div className={css.selectBg}>
//   <h2 id="select-bg" className={css.header}>Выбери фон</h2>
//   <div className={css.list}>
//     {[{
//        id: 'ex',
//        src: img,
//        thumbnailUrl: img,
//        photoPlaceholder: true, 
//     },  ...bgs].map(bg => (<button
//       onClick={() => onAdd(bg)}
//       className={css.imgItem}
//       key={bg.id}
//     >
//       <img className={css.imgItem__img}  src={bg.thumbnailUrl} alt={"bg"} />
//     </button>))}
//   </div>
// </div>);