import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DataItem } from '../types'
import { RootState } from '../../../store/store'

interface SelectState {
  selectedId?: DataItem['id'],
}

const initialState = { selectedId: undefined } as SelectState

const selectedSlice = createSlice({
  name: 'selected',
  initialState,
  reducers: {
    setSelected(state, action: PayloadAction<DataItem | null>) {
      return { ...state, selectedId: action.payload ? action.payload.id : undefined };
    },
  },
})

export const SelectActions = selectedSlice.actions;
export const selectedSelect = (state: RootState) => state.selected;

export default selectedSlice;