import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// ID проекта в bolt locale
const LOCALE_PROJECT_ID = `661c1db3da2a8409291ebb65`;


// const withLoadPath = process.env.NODE_ENV === 'development' ? {

console.log('envset', process.env.NODE_ENV, process.env);

// eslint-disable-next-line no-constant-condition
const withLoadPath = process.env.NODE_ENV === 'development' || process.env.REACT_APP_LOCALIZER_LOAD   ? {
  // конфигурируем путь для загрузки не существующих в locize ключей
  // addPath: `https://localizer.axlebolt.work/api/project/${LOCALE_PROJECT_ID}/namespace/add/{{ns}}/keys`, // locize//{{lng}}
  // Загрузка локализаций в реальном времени в окружении разработчика
  loadPath: `https://localizer.axlebolt.work/api/project/${LOCALE_PROJECT_ID}/locize/{{ns}}/{{lng}}`,
} : {
  // Загрузка локализаций в продакшин окружении (файлы локализации загружаются при сборке проекта в ci)
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};


//  i18n
//   .use(LanguageDetector)
//   .init()

const languageDetector = new LanguageDetector(null, {
  convertDetectedLanguage: (lng) => {
    console.log('lng-detect', lng);
    if (!lng) {
      return 'en'
    }
    if (['tr', 'ru'].includes(lng)) {
      console.log('ignore lang', lng)
      return 'en';
    }
    if (lng.includes('-')) {
      const langName = lng.split('-')[0];
      if (['tr', 'ru'].includes(langName)) {
        console.log('ignore lang', langName)
        return 'en';
      }
      return langName;
    }
    return lng;
  }
});


i18n
  // .use(LanguageDetector)
  .use(languageDetector)
  .use(Backend)
  .use(initReactI18next);

if (!i18n.isInitialized) {
  i18n.init({
    debug: process.env.NODE_ENV !== 'production', // опционально
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      ...withLoadPath,
    },
    // saveMissing: true,
  });
}
export default i18n;
