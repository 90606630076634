import { useMemo } from "react";
import { ContentItem } from "../../select-items/type";

const useNextAndPrevOrder = (items: ContentItem[]) => useMemo(() => {
  let maxOrder = 0;
  const nextAndPrevOrder = items.reduce((acc, bg, key, items) => {
    acc[bg.id] = { 
      prev: items[key - 1]?.order,
      next: items[key + 1]?.order,
      prevId: items[key - 1]?.id,
      nextId: items[key + 1]?.id,
    };
    maxOrder = Math.max(maxOrder, bg.order || 0);
    return acc; 
  }, {} as Record<string, { prev?: number, next?: number, prevId?: string, nextId?: string } >);

  return { nextAndPrevOrder, maxOrder: maxOrder + 10 };
}, [items]);

export default useNextAndPrevOrder;