import { ReactNode } from "react";
import { createPortal } from "react-dom";

import css from '../styles/error.module.css';

interface ErrorProps {
  id: string;
  children: ReactNode;
}

export default function ErorMsg({ id, children }: ErrorProps) {
  return (
  <>
    {createPortal(
    <div className={css.error}>{children}</div>,
    document.getElementById('msg') as HTMLElement,
    id
    )}
  </>
  );
}