import { Form, Icon } from '@web-products/ui-kit';
import css from '../styles/phrase-creator.module.css';
import { ModalChildrenProps } from "../../layouts/types";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dataURLtoFile from '../../bake-image/utils/dataUrlToFile';
import { useUploadImageMutation } from '../../admin-actions/api/sticker-item-api';
import { useTranslation } from 'react-i18next';
import { useGetBgsQuery } from '../../editor/api/templates-api';

export default function PhtaseCreator({ onClose }: ModalChildrenProps) {
  const [text, setText] = useState('');
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const textSizeRef = useRef<HTMLDivElement | null>(null);


  const WIDTH = 303;
  const PADDING = 20;
  const OFFSET = 5;

  const lines =  useMemo(() => {
    return text.split('\n');
  }, [text]);

  const HEIGHT = useMemo(() => {
    return (20 + OFFSET) * lines.length + PADDING * 2;
  }, [lines]);

  const linesSize = useMemo(() => {
    return lines.map(textLine => {
      if (!textSizeRef.current) {
        return;
      }
      textSizeRef.current.innerText = textLine;
      return textSizeRef.current.getBoundingClientRect();
    });
  }, [lines]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    const pixelRatio = 1024 / WIDTH;

    if (pixelRatio > 1) {
      canvasRef.current.width = WIDTH * pixelRatio;
      canvasRef.current.height = HEIGHT * pixelRatio;

      // canvas.style.width = width + "px";
      // canvas.style.height = height + "px";

      ctx.scale(pixelRatio, pixelRatio);
    }
    
    ctx.clearRect(0, 0, WIDTH, HEIGHT);
    ctx.fillStyle = "rgba(255, 255, 255, 0.10)";
    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    ctx.save();
    

    ctx.fillStyle = "#FFF";
    ctx.font = "bold 20px Roboto";


    lines.forEach((lineText, i) => {
      console.log('size', linesSize[i]);
      const width = linesSize[i]?.width || 0;
      const height = linesSize[i]?.height || 0;
      console.log('widht--', WIDTH, width);


      const x = WIDTH / 2 - width / 2;
      const y = height + i * (height) + PADDING - OFFSET;

      // ctx.fillStyle = "rgba(255, 255, 255, 0.20)";
      // ctx.fillRect(x, y - height, width, height);
      // ctx.fillStyle = "rgba(255, 0, 255, 1)";
      ctx.fillText(lineText, x, y);

    });
    
    ctx.restore();
    

  }, [HEIGHT, lines, linesSize]);


  const { i18n } = useTranslation();
  const { data } = useGetBgsQuery({ locale: i18n.language });

  const maxOrder = useMemo(() => {
    // console.log('max-order--', data?.phrases[data.phrases.length - 1].order);
    return (data?.phrases[data?.phrases?.length - 1]?.order || 0 + 10);
  }, [data]);

  const [updateImage] = useUploadImageMutation();

  const onUpload = useCallback(() => {
    const base64Str = canvasRef.current?.toDataURL("image/png", 0.7);
    if (base64Str) {
      const file = dataURLtoFile(base64Str, 'phrase.png');
      console.log(file)
      if (file) {
        updateImage({
          file,
          templateType: 'phrase',
          order: maxOrder + 10,
          locale: i18n.language,
        }).unwrap().then(() => {
          onClose();
        });
        // onUploadResult(file);
      }
    }
  }, [i18n.language, maxOrder, onClose, updateImage]);

  return (<div className={css.phraseCreator}>
    <Form.Button type="button" preset="flat" onClick={onClose}>
      <Icon icon="Cross" />
    </Form.Button>
    <Form.InputWrap htmlFor="new-text" title="Create phrase" description="" className={css.editor}>
      <Form.TextArea className={css.editor__text} value={text} onChange={e => setText(e.target.value.toUpperCase())} id="new-text" />
      <canvas ref={canvasRef} className={css.editor__preview} id="canvas" width={WIDTH} height={HEIGHT}></canvas>
    </Form.InputWrap>
    <div id="text-size" ref={textSizeRef} className={css.editor__size}>
    </div>
    <Form.Button type="button" onClick={onUpload}>
      <Icon icon="Check" />
    </Form.Button>
  </div>);
}