import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import imglyRemoveBackground from "@imgly/background-removal"

import ReactD3Wrap from "../react-d3-wrap/react-d3-wrap";
import { Size } from "../react-d3-wrap/types";
import { DataItem, EditModes } from "./types";
import { initDrag } from "./drag";
import { useDispatch, useSelector } from "react-redux";
import { LayerActions, layersFaceSelect, layersSelectors } from "./store/layers";
import useDraw from "./hooks/draw";
import { SelectActions, selectedSelect } from "./store/selected";

import css from './styles/editor.module.css';
import { useTouch } from "./hooks/touch";
import { EditModeActions, EditModeSelect } from "./store/edit-mode";
import { Button } from "../select-items/components/Button";
import Bake from "../bake-image/Bake";
import EditorHeader from "./components/editor-header";
import moveTo from "../layouts/utils/moveTo";
import TranslateWrap from "../admin-actions/components/TranslateWrap";


interface EditorProps {
  // setScrollPosition: (y: number) => void;
  readonly selectFace: boolean;
  onChangeSize?: (size: Size) => void;
  onRandom?: () => void;
  onUploadResult: (file: File) => void;
}


export default function Editor({ selectFace, onChangeSize, onRandom, onUploadResult }: EditorProps) {
  const dispatch = useDispatch();

  const drag = useMemo(() => initDrag<DataItem>(undefined, (event, d) => {

    if (d.id === 'bg') {
      // debugger;
    }

    dispatch(LayerActions.updateLayer({
      id: d.id,
      changes: {
        x: event.x,
        y: event.y,
      }
    }))
  }), [dispatch]);



  const layersAll = useSelector(layersSelectors.selectAll);
  const layersFaces = useSelector(layersFaceSelect);

  const layers = selectFace ? layersFaces : layersAll;

  const layersMap = useSelector(layersSelectors.selectEntities);

  const [size, setSize] = useState<{ size: Size, svg: SVGSVGElement | null }>({
    size: { width: 0, height: 0 },
    svg: null,
  });

  useEffect(() => {
    onChangeSize?.(size.size);
  }, [size, onChangeSize]);


  const startPosition = useRef([0, 0]);

  const scale = useMemo(() => {
    return initDrag<DataItem>((event) => {
      startPosition.current = [event.x, event.y];
    },
    function (event, d) {
  
    const delta = Math.max((event.x - startPosition.current[0]), (event.y - startPosition.current[1]));

    dispatch(LayerActions.updateLayer({
      id: d.id,
      changes: {
        width: d.width + delta,
        height: d.height + delta,
      }
    }))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  )}, [dispatch, layersMap, startPosition.current]);


  const rotate = useMemo(() => initDrag<DataItem>((event) => {
    startPosition.current = [event.x, event.y];
  }, (event, d) => {
    const delta = (event.x - startPosition.current[0]) + (event.y - startPosition.current[1]);
    const rotate = d.rotate + delta / 5;
    dispatch(LayerActions.updateLayer({
      id: d.id,
      changes: {
        rotate,
      }
    }))
  }), [dispatch]);

  // TODO replace with load default
  // useEffect(() => {
  //   if (layers.length === 0) {
  //     dispatch(LayerActions.setLayers(sampleLayerData));
  //   }
  // }, [dispatch, layers]);

  const onSelect = useCallback((d: DataItem | null) => {
    dispatch(SelectActions.setSelected(d));
  }, [dispatch]);

  const onRemove = useCallback((id: DataItem['id']) => {
    dispatch(LayerActions.removeLayer(id));
  }, [dispatch]);

  const selected = useSelector(selectedSelect);


  const { rotate: rotateTouch } = useTouch(size.size, selected.selectedId);

  const bgTrans = useSelector(EditModeSelect) === EditModes.SELECT_PHOTO;
  
  const { draw } = useDraw({  
    size: size.size, drag, scale, rotate, touchRotate: rotateTouch, onSelect, onRemove, selectedId: selected.selectedId, bgTrans
  });

  const onReady = useCallback((size: Size, svg: SVGSVGElement) => {
    setSize({ size, svg });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draw, layers]);


  const onSubmitPhotoEdit = useCallback(() => {
    dispatch(EditModeActions.setEditMode(EditModes.DEFAULT));
    dispatch(LayerActions.removeLayer("child-control"));
    dispatch(SelectActions.setSelected(null));

    setTimeout(() => {
      moveTo('select_stickers')();
    }, 800)
    
  }, [dispatch]);


  useEffect(() => {
    if (size.svg && size.size) {
      draw(layers, size.size, size.svg);

      // setScrollPosition(size.svg.getBoundingClientRect().top)
    }
  }, [size, draw, layers, layersMap, selected.selectedId]);


  return (<div className={css.wrap}>
    {/* <Menu onAdd={() => {}} /> */}
    {selectFace && <EditorHeader onRandom={onRandom} />}
    <div className={css.area} id={selectFace ? 'editor_top' : 'editor_bottom'}>

      {/* <div className={css.remove}></div> */}
      <ReactD3Wrap className={css.editor} onReady={onReady} />
      {!selectFace && <Bake svg={size.svg} onUploadResult={onUploadResult} />}
      {selectFace && <div className={css.editor__confirmPhoto} >
        {bgTrans && <Button preset="yellow" onClick={onSubmitPhotoEdit}><TranslateWrap>editor:save-ph</TranslateWrap></Button>}
      </div>}
    </div>
    {/* <div>
      <Layers layers={layers} />
      <DropFile onChange={onAddLayer} />
    </div> */}
  </div>)
}