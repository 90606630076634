import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Editor from "./modules/editor/editor";
import Layout from "./modules/layouts/Layout";
import Character from "./modules/select-items/components/character";
import Phrase from "./modules/select-items/components/phrase";
import SelectBg from "./modules/select-items/components/select-bg";
import Sticker from "./modules/select-items/components/sticker";
import {
  LayerActions,
  layersSelectors,
  selectBg,
  selectItemType,
} from "./modules/editor/store/layers";
import { DataItemNew } from "./modules/editor/types";
import Photo from "./modules/select-items/components/photo";
import { useContext, useEffect, useState } from "react";
import { useGetBgsQuery } from "./modules/editor/api/templates-api";
import HideContent from "./modules/layouts/components/hide-content";
import { Welcome } from "./modules/welcome";
import Modal from "./modules/layouts/components/Modal";
import ModerationPending from "./modules/editor/components/moderation-pending";
import ModerationFailed from "./modules/editor/components/moderation-failed";
import useUploadModeration from "./modules/select-items/hooks/useUploadModeration";
import FinalScreen from "./modules/layouts/components/FinalScreen";
import LoadingModal from "./modules/layouts/components/loading-modal";
import { Size } from "./modules/react-d3-wrap/types";
import NextBack from "./modules/layouts/components/next-back";
import useAddRandom from "./modules/select-items/hooks/useAddRandom";
import { useUploadResultMutation } from "./modules/editor/api/image-api";
import { useNavigate } from "react-router-dom";
// import { BrowserView } from "react-device-detect";
// import BlockDesktop from "./modules/layouts/components/block-desktop";
import Cookie from "./modules/layouts/components/cookie";
import { YM_EVENTS, ymEvent } from "./modules/metrica/ym";
import moveTo from "./modules/layouts/utils/moveTo";
import { AdminContext } from "./modules/admin-actions/context/AdminContext";
import PhtaseCreator from "./modules/select-items/components/PhraseCreator";
import { Helmet } from "react-helmet";

function App() {
  const layers = useSelector(layersSelectors.selectAll);
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();

  const [localesLoaded, setLocalesLoaded] = useState(false);

  useEffect(() => {
    if (!localesLoaded) {
      Promise.all([
        i18n.loadNamespaces("welcome"),
        i18n.loadNamespaces("editor"),
        i18n.loadNamespaces("select-items"),
        i18n.loadNamespaces("postcard"),
      ]).then(() => {
        setLocalesLoaded(true);
      });
    }
  }, [i18n, localesLoaded, setLocalesLoaded]);

  const { data, isSuccess } = useGetBgsQuery({ locale: i18n.language });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateScrollPosition = (y: number) => {
    // scrollPosition.current = y;
  };

  const onAddLayer = (layer: DataItemNew, scrollTo?: string) => {
    dispatch(
      LayerActions.createLayer({
        ...layer,
        // order: 1000 + layers.length * 1000,
      }),
    );

    setTimeout(() => {
      if (scrollTo) {
        const el = document.getElementById(scrollTo);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 10);
  };

  const removeLayerBySrc = (src: string) => {
    const id = layers.find((l) => l.src === src)?.id;
    if (id) {
      dispatch(LayerActions.removeLayer(id));
    }
  };

  // modal state
  const [modalState, setModalState] = useState<
    "MODETATION_PENDING" | "MODERATION_FAILED" | "NO"
  >("NO");

  // upload result
  const navigate = useNavigate();

  const [uploadResult, { isLoading: isLoadingResult, isError: isErrorResult }] =
    useUploadResultMutation();

  useEffect(() => {
    if (isErrorResult) {
      setModalState("MODERATION_FAILED");
    }
  }, [isErrorResult, setModalState]);

  const onUploadResult = (file: File) => {
    ymEvent(YM_EVENTS.EDITOR_SHARE_RESULT);
    uploadResult({ file, lang: i18n.language })
      .unwrap()
      .then((res) => {
        if (res.moderationDecision === "ACCEPT") {
          // shareImage({ url: res.cdnUrl });
          navigate(`/postcard/${res.uuid}`);
        } else {
          setModalState("MODERATION_FAILED");
        }
      })
      .catch(() => {
        setModalState("MODERATION_FAILED");
      });
  };

  // upload moderation
  const { onAddPhoto, isLoadingModeration, isFailModeration } =
    useUploadModeration();

  const bgLayer = useSelector(selectBg);
  const photoPlaceholder = bgLayer?.photoPlaceholder;

  const onChangeBg = () => {
    dispatch(LayerActions.setLayers([]));
    setTimeout(() => {
      moveTo("select-bg")();
    }, 100);
  };

  useEffect(() => {
    if (isFailModeration) {
      setModalState("MODERATION_FAILED");
    }
  }, [isFailModeration, setModalState]);

  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const onChangeSize = (newSize: Size) => {
    if (size.width !== newSize.width) {
      console.log("setSIze", newSize);
      setSize(newSize);
    }
  };

  const { onRandom } = useAddRandom({
    size,
    onAddLayer,
    stickers: data?.stickers || [],
    pharases: data?.phrases || [],
    characters: data?.characters || [],
  });

  const selectedStickers = useSelector(selectItemType("sticker"));

  const selectedPhrases = useSelector(selectItemType("text"));

  const selectedCharacters = useSelector(selectItemType("character"));

  const isLoading = !localesLoaded || !isSuccess;

  const showCharacters = bgLayer && !photoPlaceholder;

  // const [displayBlock, setDisplayBlock] = useState(true);

  const isAdmin = useContext(AdminContext);

  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <div className="App">
      <Layout>
        <Helmet>
          <title>{t("postcard:title")}</title>
          <meta name="description" content={t("postcard:description")} />
          <meta property="og:title" content={t("postcard:title")} />
          <meta property="og:description" content={t("postcard:description")} />
          {/* <meta property="image" content={data?.cdnUrl} />
          <meta property="og:image" content={data?.cdnUrl} /> */}
        </Helmet>
        {/* <BrowserView>
          <Modal display={displayBlock} onClose={() => setDisplayBlock(false)}>
            {BlockDesktop}
          </Modal>   
        </BrowserView> */}
        <Modal
          display={isLoadingModeration || isLoadingResult}
          onClose={() => {
            setModalState("NO");
          }}
        >
          {ModerationPending}
        </Modal>
        <Modal
          display={modalState === "MODERATION_FAILED"}
          onClose={() => setModalState("NO")}
        >
          {ModerationFailed}
        </Modal>
        <Modal display={isLoading} onClose={() => {}}>
          {LoadingModal}
        </Modal>
        <Modal
          display={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
          }}
        >
          {PhtaseCreator}
        </Modal>
        {!isLoading && (
          <>
            <Cookie />
            <Welcome />
            {data && (
              <>
                {layers.length < 1 && (
                  <SelectBg onSelect={onAddLayer} items={data.backgrounds} />
                )}
                {layers.length > 0 && (
                  <Editor
                    onUploadResult={onUploadResult}
                    selectFace={true}
                    onChangeSize={onChangeSize}
                    onRandom={onRandom}
                  />
                )}
                {bgLayer && (
                  <Photo
                    canSelectPhoto={photoPlaceholder}
                    onSelectPhoto={onAddPhoto}
                    onChangeBg={onChangeBg}
                  />
                )}
                <HideContent hide={!bgLayer || !isAdmin}>
                  {/* <Random /> */}
                  {/* <Tape /> */}

                  {/* <NextBack  nextPoint="select_stickers" backPoint="select_photo" /> */}
                  <Sticker
                    items={data.stickers}
                    onSelect={onAddLayer}
                    onRemove={removeLayerBySrc}
                    selected={selectedStickers}
                    nextPoint={
                      showCharacters ? "select_characters" : "select_phrases"
                    }
                  />

                  {!showCharacters && (
                    <NextBack
                      nextEnabled={selectedStickers.length > 0}
                      nextPoint="select_phrases"
                      backPoint="select_photo"
                    />
                  )}

                  {showCharacters && (
                    <NextBack
                      nextEnabled={selectedStickers.length > 0}
                      nextPoint="select_characters"
                      backPoint="select_photo"
                    />
                  )}

                  {showCharacters && (
                    <Character
                      onRemove={removeLayerBySrc}
                      selected={selectedCharacters}
                      items={data.characters}
                      onSelect={onAddLayer}
                      nextPoint="select_phrases"
                    />
                  )}

                  {showCharacters && (
                    <NextBack
                      nextEnabled={selectedCharacters.length > 0}
                      nextPoint="select_phrases"
                      backPoint="select_stickers"
                    />
                  )}

                  <Phrase
                    onCreatePhrase={() => setShowCreateModal(true)}
                    onRemove={removeLayerBySrc}
                    size={size}
                    onSelect={onAddLayer}
                    selected={selectedPhrases}
                    phrasesList={data.phrases}
                    nextPoint="final_screen"
                  />

                  {!showCharacters && (
                    <NextBack
                      nextEnabled={selectedPhrases.length > 0}
                      nextPoint="final_screen"
                      backPoint="select_stickers"
                    />
                  )}

                  {showCharacters && (
                    <NextBack
                      nextEnabled={selectedPhrases.length > 0}
                      nextPoint="final_screen"
                      backPoint="select_characters"
                    />
                  )}

                  {layers.length > 0 && (
                    <FinalScreen>
                      <Editor
                        selectFace={false}
                        onUploadResult={onUploadResult}
                      />
                    </FinalScreen>
                  )}
                </HideContent>
              </>
            )}
          </>
        )}
      </Layout>
    </div>
  );
}

export default App;
