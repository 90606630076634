import cn from 'classnames';
import { TemplateData } from '../../editor/api/types';
import { DataItem, DataItemNew } from '../../editor/types';
import css from '../styles/sticker.module.css';
import { ContentItem } from '../type';
import { useContext, useMemo } from 'react';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';
import EditableItemWrap from '../../admin-actions/components/editableItemWrap';
import useNextAndPrevOrder from '../../admin-actions/selector/nextAndPrevOrderItem';
import UploadItemToList from '../../admin-actions/components/uploadItemToList';
import { AdminContext } from '../../admin-actions/context/AdminContext';

interface SelectBgProps {
  onSelect: (data: DataItemNew, scrollTo?: string) => void;
  onRemove: (src: string) => void;
  items: TemplateData[];
  selected: DataItem['id'][];
  nextPoint?: string;
}

export default function Sticker({ onSelect, onRemove, items, selected, nextPoint }: SelectBgProps) {
  const onRemoveClick = (item: ContentItem) => {
    onRemove(item.src);
  }

  const { isAdmin  } = useContext(AdminContext);

  const onAdd = (item: ContentItem) => {
    if (selected.length <= 3) {

      const nextPointScroll = selected.length === 3 ? nextPoint : undefined;
      onSelect({
        id: `sticker_${performance.now()}`,
        src: item.src,
        width: item.width || 154,
        height: item.height || 154,
        x: 21,
        y: 36,
        rotate: 0,
        isDragable: true,
        minimumSize: 100,
        layerType: 'sticker',
        // isFullSizeBg: true,
      }, nextPointScroll);
    }
  }

  const stickers = items.map(sticker => (
    {
      id: sticker.id,
      src: sticker.cdnUrl,
      width: 140,
      height: 140,
      order: sticker.order,
    }
  ));

  const lenN4 = Math.ceil(stickers.length / 4);

  const list = useMemo(() => [
    stickers.slice(0, lenN4),
    stickers.slice(lenN4, lenN4 * 2),
    stickers.slice(lenN4 * 2, lenN4 * 3),
    stickers.slice(lenN4 * 3, stickers.length),
  ], [lenN4, stickers]);


  const { maxOrder, nextAndPrevOrder } = useNextAndPrevOrder(stickers);
  

  return (<div className={css.sticker}>
    <header id="select_stickers" className={css.header}><TranslateWrap>select-items:stickers-header</TranslateWrap></header>
    {isAdmin && (<UploadItemToList templateType="sticker" maxOrder={maxOrder} />)}
    {list.map((lineList, i) => (
       <div className={css.list} key={i}>
          {lineList.map(s => (
            <EditableItemWrap
              nextOrder={nextAndPrevOrder[s.id].next}
              backOrder={nextAndPrevOrder[s.id].prev}
              prevId={nextAndPrevOrder[s.id].prevId}
              nextId={nextAndPrevOrder[s.id].nextId}
              // nextId={lineList.find(item => item.order === nextAndPrevOrder[s.id].next)?.id}
              className={css.imgItem}
              key={s.id}
              item={s}
              templateType="sticker"
            >      
              <button
              className={css.imgItem}
              key={s.id}
              onClick={() => selected.includes(s.id) ? onRemoveClick(s) : onAdd(s)}
              >
                {/* --prev-{nextAndPrevOrder[s.id].prev} -
                -next-{nextAndPrevOrder[s.id].next}--
                 */}
                <img className={cn(css.imgItem__img, s.id, { [css.imgItem__img_selected]: selected.includes(s.id) })} src={s.src} alt={s.id} />
              </button>
            </EditableItemWrap>
          ))}
      </div>
    ))}
    <div className={css.list}>
    
    </div>
  </div>);
}