export interface DataItem {
  id: string,
  src: string,
  order: number,
  width: number,
  height: number,
  x: number,
  y: number,
  rotate: number,
  isLoading?: boolean,
  isDragable: boolean,
  controlOf?: DataItem['id'],
  className?: string,
  text?: string,
  isFullSizeBg?: boolean,
  minimumSize?: number,
  stayInViewport?: boolean,
  disableRotate?: boolean,
  photoPlaceholder?: boolean,
  unDelete?: boolean,
  layerType?: 'bg' | 'sticker' | 'text' | 'photo' | 'character' | 'logo',
}

export type DataItemNew = Omit<DataItem, 'order'>;

export enum EditModes {
   DEFAULT = 'DEFAULT',
   SELECT_PHOTO = 'SELECT_PHOTO',
}