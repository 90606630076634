import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useLoadStoryQuery } from "../editor/api/image-api";
import Layout from "../layouts/Layout";

import shareImg from './assets/share.svg';
import linkImg from './assets/link.svg';
import downloadImg from './assets/download.svg';

import css from './styles/postcard.module.css';
import { Button } from '../select-items/components/Button';
import shareImage from '../bake-image/utils/shareImage';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { layersSelectors } from '../editor/store/layers';
import { YM_EVENTS, ymEvent } from '../metrica/ym';
import { LangType } from '../layouts/components/locale';
import TranslateWrap from '../admin-actions/components/TranslateWrap';

export default function Postcard() {

  const { t, i18n } = useTranslation();


  const [localesLoaded, setLocalesLoaded] = useState(false);

  useEffect(() => {
    if (!localesLoaded) {
      Promise.all([
        i18n.loadNamespaces('postcard'),
        i18n.loadNamespaces('welcome')
      ]).then(() => {
        setLocalesLoaded(true);
      })
    }
  }, [i18n, localesLoaded, setLocalesLoaded]);


  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)

    appHeight();
    
    return () => window.removeEventListener('resize', appHeight);
  }, []);


  const { id } = useParams<{ id: string }>();
  const { data } = useLoadStoryQuery({id: id as string, lang: i18n.language as LangType });

  const onShare = () => {
    ymEvent(YM_EVENTS.POSTCARD_SHARE);
    shareImage({ url: data?.previewUrl || window.location.href });
  }

  const onCopyLink = async () => {
    ymEvent(YM_EVENTS.POSTCARD_COPY_LINK);
    if (!data?.cdnUrl) {
      return;
    }
  
    try {
      await navigator.clipboard.writeText(data.previewUrl);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const onCreateNew = () => {
    ymEvent(YM_EVENTS.POSTCARD_CREATE_NEW);
    window.location.href = '/';
  }

  const onDownload = () => {
    ymEvent(YM_EVENTS.POSTCARD_DOWNLOAD);
    if (data?.cdnUrl) {
      const a = document.createElement('a');
      a.href = data?.cdnUrl;
      a.download = 'postcard.png';
      a.click();
    }
  }

  const isEditedBefore = useSelector(layersSelectors.selectAll).length > 0;

  const canShare = !!navigator.canShare;

  return (
    <Layout>
      <Helmet>
        <title>{t('postcard:title')}</title>
        <meta name="description" content={t('postcard:description')} />
        <meta property="og:title" content={t('postcard:title')} />
        <meta property="og:description" content={t('postcard:description')} />
        <meta property="image" content={data?.cdnUrl} />
        <meta property="og:image" content={data?.cdnUrl} />
      </Helmet>
      <div className={css.postcard}>
        {data?.cdnUrl && <img className={css.image} src={data?.cdnUrl} alt="Postcard" />}
      </div>
      <div className={css.tag}>
        <TranslateWrap>postcard:hashtag-remind</TranslateWrap>
      </div>
      {isEditedBefore && (<div className={css.controls}>
        {canShare && <Button className={css.button} preset="white" onClick={onShare}>
          <img className={css.button__icon} src={shareImg} alt="share" />
          <TranslateWrap>postcard:share</TranslateWrap>
        </Button>}
        <Button className={css.button} preset="white" onClick={onCopyLink}>
          <img className={css.button__icon} src={linkImg} alt="share" />
          <TranslateWrap>postcard:copy-link</TranslateWrap>
        </Button>
        <Button className={css.button} preset="white" onClick={onDownload}>
          <img className={css.button__icon} src={downloadImg} alt="share" />
          <TranslateWrap>postcard:download-picture</TranslateWrap>
        </Button>
      </div>)}
      {!isEditedBefore && (
        <div className={css.controls}>
          <Button className={css.button} preset="white" onClick={onCreateNew}>
            <img className={css.button__icon} src={linkImg} alt="share" />
            <TranslateWrap>postcard:create-new-postcard</TranslateWrap>
          </Button>
        </div>
      )}
    </Layout>
  );
}