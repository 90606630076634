import axios from "axios";
import { REACT_APP_BACKEND, emptySplitApi } from "../../../store/api"


type DesicionsType = 'IN_PROGRESS' | 'ACCEPT' | 'FAILED' | 'DECLINED';


interface RequestForPublish {
  uuid: string,
  status: DesicionsType,
}

export type TemplateType = 'sticker' | 'phrase' | 'character' | 'background';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    // publisha: build.mutation<RequestForPublish, object>({
    //   // invalidatesTags: ['TEMPLATES'],
    //   query: () => ({
    //     method: 'POST',
    //     url: `/publish`,
    //   })
    // }),
    getPusblishStatus: build.mutation<RequestForPublish, object>({
      queryFn: async () => {
        try {
          const { data: { uuid } } = await axios.post<RequestForPublish>(`${REACT_APP_BACKEND}/publish`);

          let result = await axios.get<RequestForPublish>(`${REACT_APP_BACKEND}/publish/${uuid}`);

          while (result.data.status === 'IN_PROGRESS') {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            result = await axios.get(`${REACT_APP_BACKEND}/publish/${uuid}`);
          }

          return { data: result.data };       
        } catch (e) {
          return { 
            error: {
              status: 500,
              data: { decision: 'FAILED' },
              uuid: '',
              cdnUrl: '',
            }
          }
        }
      }
    }),
})
});

export const { useGetPusblishStatusMutation } = extendedApi 