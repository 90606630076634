import { FormEvent, ReactNode, useContext, useState } from "react";
import cn from 'classnames';
import { TemplateType, useDeleteImageMutation, useUpdateImageMutation } from "../api/sticker-item-api";
import { AdminContext } from "../context/AdminContext";

import css from '../styles/editableItemWrap.module.css';
import { Form, Icon, IconFill } from "@web-products/ui-kit";
import { ContentItem } from "../../select-items/type";
import ErorMsg from './error-msg';

interface ImageChangeWrapProps {
  item: ContentItem,
  children: ReactNode,
  templateType: TemplateType,
  className: string,
  nextOrder?: number,
  backOrder?: number,
  nextId?: string,
  prevId?: string,
}

export default function EditableItemWrap({ 
  item, className, children, templateType, nextOrder, backOrder, nextId, prevId
}: ImageChangeWrapProps) {  
  const { isAdmin  } = useContext(AdminContext);

  const [del, { isLoading: isLoadingDelete} ] = useDeleteImageMutation();
  const [update, { isLoading: isMutationLoading, error: errorUpload }] = useUpdateImageMutation();


  const isLoading = isLoadingDelete || isMutationLoading;

  const onDeleteImg = () => {
    del({
      id: item.id,
      templateType,
    })
  };

  const [orderVal, setOrderVal] = useState(`${item.order}`);

  const onEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    update({
      id: item.id,
      order: +orderVal,
      templateType,
    });
  }


  const onChangeOrder = (direction: 'NEXT' | 'PREV') => () => {
    let currentOrder: number = 0;
    let otherOrder: number = 0;
    let otherId: string | undefined = '';

    if (direction === 'NEXT') {
      currentOrder = nextOrder as number;
      otherOrder = item.order as number;
      otherId = nextId;
    } else {
      currentOrder = backOrder as number;
      otherOrder = item.order as number;
      otherId = prevId;
    }

    update({
      id: item.id,
      order: currentOrder,
      templateType,
    });

    update({
      id: otherId,
      order: otherOrder,
      templateType,
    });
  }

  const { error, status } = errorUpload as { status: number, error: string } || { status: 0, error: '' };
  const errorMsg = errorUpload ? (<ErorMsg id={`list_${templateType}_item`}>{`${status || 'UNKNOWN'} ${error || ''}`}</ErorMsg>) : null;

  if (isAdmin) {
    return (
      <div draggable="true" onDragStart={console.log} className={cn(css.editableWrap, className)}>
        {errorMsg}
        {children}
        <div className={css.controls}>
          {isLoading && <Icon icon="RingLoading" fill={IconFill.WHITE} className={css.icon} />}
          {typeof backOrder !== 'undefined' && <Form.Button 
            type="button" onClick={onChangeOrder('PREV')} className={cn(css.button, css.icon)}>◀</Form.Button>}
          {typeof nextOrder !== 'undefined' && <Form.Button 
            type="button" onClick={onChangeOrder('NEXT')} className={cn(css.button, css.icon)}>▶</Form.Button>}
          <form onSubmit={onEdit} className={css.controls__form}>
              <Form.Input className={css.controls__orderVal} value={orderVal} onChange={e => setOrderVal(e.target.value)} />
              <Form.Button type="submit" className={css.button} >
                <Icon icon="Check" fill={IconFill.DARK} className={css.icon} />
              </Form.Button>
          </form>
          <Form.Button 
            type="button" onClick={onDeleteImg} className={css.button}>
              <Icon icon="Cross" fill={IconFill.WHITE} className={css.icon} />
          </Form.Button>
        </div>
      </div>
    );
  }
  return <>{children}</>;
}