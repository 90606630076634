import { Button } from "../../select-items/components/Button";

import nextImg from '../assets/next.svg';

import css from '../styles/next-back.module.css';
import moveTo from "../utils/moveTo";
import TranslateWrap from "../../admin-actions/components/TranslateWrap";
// import cn from 'classnames';

interface NextBackProps {
  nextPoint: string;
  backPoint: string;
  nextEnabled?: boolean;
  backEnabled?: boolean;
}

export default function NextBack({ nextPoint, backPoint, nextEnabled, backEnabled }: NextBackProps) {
  return (<div className={css.nextBack}>
    <Button className={css.buttonBack} onClick={moveTo(backPoint)} preset={backEnabled ? 'yellow' : 'white'}><TranslateWrap>editor:back</TranslateWrap></Button>
    <Button className={css.buttonNext} onClick={moveTo(nextPoint)} preset={nextEnabled ? 'yellow' : 'white'}>
      <TranslateWrap>editor:next</TranslateWrap>
      {nextEnabled && <img src={nextImg} alt="next" className={css.nextImg} />}
    </Button>
  </div>);
}