import { useContext, useState } from 'react';
import cn from 'classnames';
import expandImg from '../assets/expand.svg';
import { DataItem, DataItemNew } from '../../editor/types';
import Header from '../../layouts/components/header';
import css from '../styles/phrase.module.css';
import { ContentItem } from '../type';

import { TemplateData } from '../../editor/api/types';
import { LayerActions } from '../../editor/store/layers';
import { useDispatch } from 'react-redux';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';
import useNextAndPrevOrder from '../../admin-actions/selector/nextAndPrevOrderItem';
import EditableItemWrap from '../../admin-actions/components/editableItemWrap';
import { Form } from '@web-products/ui-kit';
import { AdminContext } from '../../admin-actions/context/AdminContext';

interface SelectPhraseProps {
  readonly onSelect: (data: DataItemNew, scrollTo?: string) => void;
  onRemove: (src: string) => void;
  readonly selected: DataItem['id'][];
  readonly size: { width: number, height: number },
  readonly phrasesList: TemplateData[],
  readonly nextPoint?: string;
  readonly onCreatePhrase: () => void;
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Phrase({ onSelect, onRemove, selected, phrasesList, size, nextPoint, onCreatePhrase }: SelectPhraseProps) {
  // const phraseList: ContentItem[] = [
  //   {
  //     id: 'textImg',
  //     src: textImg,
  //     width: 130,
  //     height: 143.951,
  //   }, 
  // ];

  const phraseList = phrasesList.map(phrase => (
    {
      id: phrase.id,
      src: phrase.cdnUrl,
      order: phrase.order,
    }
  ));

  const dispatch = useDispatch();
  // TODO fix duplicate
  const onClickPhrase = async (p: ContentItem) => {

    if (selected.length > 0) {
      dispatch(LayerActions.removeBySrc({ src: selected[0] }));
    }

    const img = new Image();
    img.onload = () => {
      const ratio = img.width / img.height;

      const width = size.width;
      const height = width / ratio;

      onSelect({
        id: `phrase_${performance.now()}`,
        src: p.src,
        width: width,
        height: height,
        x: 0,
        y: 200,
        rotate: 0,
        isDragable: true,
        disableRotate: true,
        stayInViewport: true,
        layerType: 'text',
      }, nextPoint);

    };

    img.src = p.src;
  }

  const onClickRemove =(p: ContentItem) => {
    onRemove(p.src);
  }

  const [expand, setExpand] = useState(false);

  const { nextAndPrevOrder } = useNextAndPrevOrder(phraseList);
  
  const { isAdmin  } = useContext(AdminContext);


  return (<div className={css.phrase}>
    <Header id="select_phrases" className={css.header}><TranslateWrap>select-items:select-congrats</TranslateWrap></Header>
    <div className={css.list}>
      {isAdmin && <Form.Button type="button" onClick={onCreatePhrase}>+ phrase</Form.Button>}
      {phraseList.slice(0, expand ? phraseList.length : 3).map(p => (
        <EditableItemWrap
          nextOrder={nextAndPrevOrder[p.id].next}
          backOrder={nextAndPrevOrder[p.id].prev}
          nextId={nextAndPrevOrder[p.id].nextId}
          prevId={nextAndPrevOrder[p.id].prevId}
          className={css.imgItem}
          key={p.id}
          item={p}
          templateType="phrase"
        >    
          <button
            className={cn(css.phrase__item, {[css.phrase__item_selected]: selected.includes(p.src)})}
            key={p.id}
            onClick={() => selected.includes(p.src) ? onClickRemove(p) : onClickPhrase(p)}
          >
            <img className={css.item} key={p.id} src={p.src} alt={""} />
          </button>
        </EditableItemWrap>
      ))}
      {!expand && <button onClick={() => setExpand(true)} className={css.list__expand}>
        <TranslateWrap>select-items:expand</TranslateWrap> <img src={expandImg} alt="expand" />
      </button>}
    </div>
  </div>);
}