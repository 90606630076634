import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { EditModes } from '../types'
import { RootState } from '../../../store/store'

interface EditModeState {
  editMode: EditModes
}

const initialState = { editMode: EditModes.DEFAULT } as EditModeState

const editModeSlice = createSlice({
  name: 'editMode',
  initialState,
  reducers: {
    setEditMode(state, action: PayloadAction<EditModes>) {
      return { ...state, editMode: action.payload };
    },
  },
})

export const EditModeActions = editModeSlice.actions;
export const EditModeSelect = (state: RootState) => state.editMode.editMode;

export default editModeSlice;