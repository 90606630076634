export enum YM_EVENTS {
  'WELCOME_CREATE_BUTTON' = 'WELCOME_CREATE_BUTTON',
  'EDITOR_ADD_PHOTO' = 'EDITOR_ADD_PHOTO',
  'EDITOR_CHANGE_PHOTO' = 'EDITOR_CHANGE_PHOTO',
  'EDITOR_CHANGE_BG' = 'EDITOR_CHANGE_BG',
  'EDITOR_RANDOM_CHOICE' = 'EDITOR_RANDOM_CHOICE',
  'EDITOR_SHARE_RESULT' = 'EDITOR_SHARE_RESULT',
  'POSTCARD_SHARE' = 'POSTCARD_SHARE',
  'POSTCARD_COPY_LINK' = 'POSTCARD_COPY_LINK',
  'POSTCARD_DOWNLOAD' = 'POSTCARD_DOWNLOAD',
  'POSTCARD_CREATE_NEW' = 'POSTCARD_CREATE_NEW',
}

export function ymEvent(targetName: YM_EVENTS) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).ym('97044528', 'reachGoal', targetName);
}

