import { useDispatch } from 'react-redux';
import { TemplateData } from '../../editor/api/types';
import { DataItem, DataItemNew } from '../../editor/types';
import css from '../styles/character.module.css';
import { ContentItem } from '../type';
import cn from 'classnames';
import { LayerActions } from '../../editor/store/layers';
import Header from '../../layouts/components/header';
import { useContext } from 'react';
import { AdminContext } from '../../admin-actions/context/AdminContext';
import UploadItemToList from '../../admin-actions/components/uploadItemToList';
import useNextAndPrevOrder from '../../admin-actions/selector/nextAndPrevOrderItem';
import EditableItemWrap from '../../admin-actions/components/editableItemWrap';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

interface SelectBgProps {
  onSelect: (data: DataItemNew, scrollTo?: string) => void;
  onRemove: (src: string) => void;
  items: TemplateData[];
  selected: DataItem['id'][];
  nextPoint?: string;
}

export default function Character({ onSelect, onRemove, items, selected, nextPoint}: SelectBgProps) {
  const { isAdmin  } = useContext(AdminContext);

  const dispatch = useDispatch();

  const onRemoveClick = (item: ContentItem) => {
    onRemove(item.src);
  }

  const onAdd = (item: ContentItem) => {

    if (selected.length > 0) {
      dispatch(LayerActions.removeBySrc({ src: selected[0] }));
    }

    onSelect({
      id: `sticker_${performance.now()}`,
      src: item.src,
      width: 249,
      height: 443,
      x: 0,
      y: 0,
      rotate: 0,
      isDragable: true,
      minimumSize: 150,
      layerType: 'character',
    }, nextPoint);
  }


  const characters = items.map(sticker => (
    {
      id: sticker.id,
      src: sticker.cdnUrl,
      width: 248,
      height: 373,
      order: sticker.order,
    }
  ));


  const { nextAndPrevOrder, maxOrder } = useNextAndPrevOrder(characters);

  return (<div className={css.character}>
    <Header id="select_characters" className={css.header}>
      <TranslateWrap>select-items:select-char</TranslateWrap>
    </Header>
    {isAdmin && (<UploadItemToList templateType="character" maxOrder={maxOrder} />)}
    <div className={css.list}>
      {characters.map(c => (
        <EditableItemWrap
        nextOrder={nextAndPrevOrder[c.id].next}
        backOrder={nextAndPrevOrder[c.id].prev}
        nextId={nextAndPrevOrder[c.id].nextId}
        prevId={nextAndPrevOrder[c.id].prevId}
        className={css.imgItem}
        key={c.id}
        item={c}
        templateType="character"
        >     
        <button
          className={css.imgItem}
          key={c.id}
          onClick={() => selected.includes(c.id)  ? onRemoveClick(c) : onAdd(c)}
        >
          <img 
            className={cn(css.item, { 
              [css.item__selected]: selected.includes(c.id) 
            })} 
            src={c.src} alt={c.id} 
          />
        </button>
        </EditableItemWrap>
      ))}
    </div>
  </div>);
}