import { DataItem } from "../../editor/types";
import imageRenderToCanvas from "./imageRenderToCanvas";


export async function compresssImage(img: HTMLImageElement, canvas: HTMLCanvasElement, outputWidth: number, outputQuality: number) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  const { width, height } = img;

  const pixelRatio = outputWidth / width;

  // if (outputWidth) {
  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;

  // canvas.style.width = width + "px";
  // canvas.style.height = height + "px";


  ctx.scale(pixelRatio, pixelRatio);
  // }


  console.log('img-source', width, canvas.height);

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);

  const src = img.src;
  const image = new Image();
  image.crossOrigin="anonymous";
  image.src = src;

  await new Promise((resolve) => {
    image.onload = () => {
      resolve(null);
    }
  });


  imageRenderToCanvas(
    ctx,
    image,
    { x: 0, y: 0},
    { width: width , height: height },
    0,
    1);

  return { base64: canvas.toDataURL("image/jpeg", outputQuality), width: canvas.width, height: canvas.height};
}


export default function useBakeImg(svg: SVGSVGElement, layers: DataItem[], canvas: HTMLCanvasElement | null, ) {

  const bake = async () => {
    if (!canvas) {
      return false;
    }

    const { width, height } = svg?.getBoundingClientRect() || { width: 0, height: 0 };

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return false;
    }


    const pixelRatio = 1024 / width;

    if (pixelRatio > 1) {
      canvas.width = width * pixelRatio;
      canvas.height = height * pixelRatio;

      // canvas.style.width = width + "px";
      // canvas.style.height = height + "px";

      ctx.scale(pixelRatio, pixelRatio);
    }

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    await (async function () {
    for (const layer of layers) {

      if (layer.src === 'none' && layer.text) {
        console.log('text');

        ctx.save();
        ctx.font = "20px Roboto";
        ctx.fillText(layer.text, layer.x, layer.y);
        
        ctx.restore();
        continue;
      }

      const src = layer.src;
      const image = new Image();
      image.crossOrigin="anonymous";
      image.src = src;

      await new Promise((resolve) => {
        image.onload = () => {
          resolve(null);
        }
      });

      const size = layer.id === 'bg' ? { width, height } : {
        width: layer.width,
        height: layer.height
      }
      imageRenderToCanvas(
        ctx,
        image,
        { x: layer.x, y: layer.y },
        { width: size.width, height: size.height },
        layer.rotate,
        1);
    }
    })();
  }

  return { bake };
}