// import { ReactNode } from "react";
import { Form, Icon, IconFill } from "@web-products/ui-kit";
import { TemplateType, useUploadImageMutation } from '../api/sticker-item-api';

import css from '../styles/editableListOfItems.module.css';
import ErorMsg from "./error-msg";

interface UploadItemToListProps<P> {
  templateType: TemplateType,
  maxOrder: number,
  extraParams?: P
}

export default function UploadItemToList<P>({ templateType, maxOrder, extraParams }: UploadItemToListProps<P>) {
  const [updateImage, { error: errorUpload, isLoading }] = useUploadImageMutation();

  const onClickImgList = async (files: File[]) => {

    const sortedListofFiles = files.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })

    let i = maxOrder + 10;
    for (const file of sortedListofFiles) {
      await updateImage({
        file,
        templateType,
        order: i,
        ...extraParams,
      })
      i += 10;
    }
  };


  const { error, status } = errorUpload as { status: number, error: string } || { status: 0, error: '' };
  const errorMsg = errorUpload ? (<ErorMsg id={`list_${templateType}_item`}>{`${status} ${error}`}</ErorMsg>) : null;

  return (
    <>
      {errorMsg}
      <Form.File.FileUploadCustom 
        isUploaded={false}
        progress={0}
        // className={cn(css.imageChangeWrap, props.className)}
        // onChange={onClickImg}
        onChangeFiles={onClickImgList}
      >
        {({ selectedFile, selectedFileList, onChangeFile }) => (
          <Form.File.FileInputWrap
            // className={css.imageChangeWrap__label}
            id={`upload-image-id-${templateType}`}
            multiple
            locales={{}}
            selectedFile={selectedFile}
            selectedFileList={selectedFileList}
            onChangeFile={onChangeFile} 
            Button={({ onClick }) => (
              <Form.Button disabled={isLoading} type="button" preset="red" className={css.uploadButton} onClick={onClick}>
                {isLoading && <Icon icon="RingLoading" fill={IconFill.WHITE} />}
                {!isLoading && <Icon icon="Plus" fill={IconFill.WHITE} />}
                <span>upload</span>
              </Form.Button>
            )}
          />
        )}
  
      </Form.File.FileUploadCustom>
    </>
  );
}