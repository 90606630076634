import css from '../styles/button.module.css';
import cn from 'classnames';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement>{
  preset: 'yellow' | 'white';
}

interface ButtonLinkProps extends ButtonProps {
  href?: string
}

export function Button({ preset, className, ...args }: ButtonProps) {
  const presetClass = preset && css[`button_${preset}`];
  return <button {...args} className={cn(css.button, presetClass, className)}>
    {args.children}
  </button>
}


interface FileButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  preset: 'yellow' | 'white';
}

export function FileButton({ preset, className, ...args }: FileButtonProps) {
  const presetClass = preset && css[`button_${preset}`];

  return (<label htmlFor="photo_file" className={cn(css.button, presetClass, className)}>
    <input className={css.button__file} id="photo_file" onChange={args.onChange} type="file" />
    {args.children}
  </label>)
}

export function ButtonLink({ preset, className, ...args }: ButtonLinkProps) {
  const presetClass = preset && css[`button_${preset}`];
  return <a href={args.href} className={cn(css.button, presetClass, className)}>{args.children}</a>
}