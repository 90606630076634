import { ModalChildrenProps } from '../../layouts/types';
import css from '../styles/moderation-pending.module.css';
import Spinner from '../../layouts/components/spinner';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ModerationPending({ onClose }: ModalChildrenProps) {
  return (<div className={css.pending}>
    <div><TranslateWrap>editor:moderation-photo</TranslateWrap></div>
    <div><TranslateWrap>editor:moderation-time</TranslateWrap></div>
    <Spinner />
  </div>);
}