import LayoutView from "./components/LayoutView";

interface LayoutProps {
  children?: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (<LayoutView>
    {children}
  </LayoutView>);
}