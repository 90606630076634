import { Button, FileButton } from "./Button";

import css from '../styles/photo.module.css';
import { FormEvent, useRef } from "react";
import { DataItem, EditModes } from "../../editor/types";
import { useTranslation } from "react-i18next";
import { compresssImage } from "../../bake-image/utils/useBakeImg";
import { useSelector } from "react-redux";
import { EditModeSelect } from "../../editor/store/edit-mode";
import { YM_EVENTS, ymEvent } from "../../metrica/ym";
import moveTo from "../../layouts/utils/moveTo";
import TranslateWrap from "../../admin-actions/components/TranslateWrap";

interface PhotoProps {
  readonly onSelectPhoto: (photo: File, layer: DataItem) => void;
  readonly onChangeBg: () => void;
  readonly canSelectPhoto?: boolean;
}

export default function Photo({ onSelectPhoto, onChangeBg, canSelectPhoto }: PhotoProps) {
  const { t } = useTranslation();

  const canvasRef = useRef<HTMLCanvasElement>(null);


  const alreadySelected = useSelector(EditModeSelect) === EditModes.SELECT_PHOTO;
  // const { bake } = useBakeImg(svg!, layers, canvasRef.current!);

  const onChange = (e: FormEvent<HTMLInputElement>) => {
    if (!alreadySelected) {
      ymEvent(YM_EVENTS.EDITOR_ADD_PHOTO);
    } else {
      ymEvent(YM_EVENTS.EDITOR_CHANGE_PHOTO);
    }
    moveTo('editor_top')();
    console.log('change-file');
    const file = e.currentTarget.files?.[0];
    if (file) {
      console.log('file-exist');

      const reader = new FileReader();
      reader.onload = (e) => {

        console.log('reader-loaded');
        const data = e.target?.result;
        if (typeof data === 'string') {
         
          const img = new Image();
          img.onload = async () => {

            if (!canvasRef.current) {
              return;
            }

            console.log('img-source', img.width, img.height);
            const result = await compresssImage(img, canvasRef.current, 800, 0.7);

            if (!result?.base64) {
              return false;
            }

            onSelectPhoto(file, {
              id: `photo_${performance.now()}`,
              src: result.base64,
              order: 4000,
              width: 300,
              height: img.height * (300 / img.width),
              x: 21,
              y: 36,
              rotate: 0,
              isDragable: true,
              unDelete: true,
            });
            // alert(this.width + 'x' + this.height);
          }
          img.src = data;
        }
      }
      reader.readAsDataURL(file);
    }
  }

  return (<div className={css.photo}>

    <canvas
      style={{
        width: '1px',
        height: '1px',
      }}
      ref={canvasRef}
    />
    {canSelectPhoto && <FileButton preset="yellow" className={css.button}  onChange={onChange}>{alreadySelected ? t('editor:change-ph') : t('editor:add-your-photo')}</FileButton>}
    <Button preset="white" className={css.button} onClick={onChangeBg}><TranslateWrap>editor:change-bg</TranslateWrap></Button>
  </div>)
}