import cn from 'classnames';
import { memo, useEffect, useRef } from "react";
import { Size } from "./types";

import css from './styles/wrap.module.css';

interface ReactD3WrapProps {
  className?: string,
  onReady: (size: Size, svg: SVGSVGElement) => void
}


export default memo(function ReactD3Wrap({ className, onReady }: ReactD3WrapProps) {
  const contRef = useRef<SVGSVGElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const isInit = useRef(false);

  useEffect(() => {
    if (!isInit.current && contRef.current) {
      const box = divRef.current?.getBoundingClientRect();
      if (box) {
        onReady(box, contRef.current);
        isInit.current = true;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const onResize = () => {
  //     const box = divRef.current?.getBoundingClientRect();
  //     console.log('resize', box);
  //     if (contRef.current && box) {
  //       onReady(box, contRef.current);
  //     }
  //   }

  //   window.addEventListener('resize', onResize);
  //   return () => {
  //     window.removeEventListener('resize', onResize);
  //   }
  // }, []);

  return <div className={cn(className)} ref={divRef}>
    <svg className={css.canvas} ref={contRef} />
  </div>;
}, () => false);
