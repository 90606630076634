import axios from "axios";
import { REACT_APP_BACKEND, emptySplitApi } from "../../../store/api"
import { LangType } from "../../layouts/components/locale";

interface ItemResponse {
  key: string;
  cdnUrl: string;
}

interface ResponseError {
  response: {
    data: {
      title: string,
      detail: string,
      status: number 
    }
  }
} 


export type TemplateType = 'sticker' | 'phrase' | 'character' | 'background';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    loadItems: build.query<ItemResponse, { templateType: TemplateType, }>({
      query: ({ templateType }) => ({ url: `/template/${templateType}222` }),
    }),
    uploadImage: build.mutation<ItemResponse, {
      file: File,
      templateType: TemplateType,
      order: number,
      locale?: LangType,
      photoPlaceholder?: boolean,
    }>({
      // invalidatesTags: ['MAP'],
      invalidatesTags: ['TEMPLATES'],
      queryFn: async ({
        file,
        templateType,
        order,
        locale,
        // TODO remove from here 
        photoPlaceholder
      }) => {
        const body = new FormData();

        body.append('file', file);
        body.append('order', `${order}`)

        if (photoPlaceholder) {
          body.append('photoPlaceholder', `${photoPlaceholder}`)
        }

        if (locale) {
          body.append('locale', `${locale}`)
        }

        const url = `${REACT_APP_BACKEND}/template/${templateType}`;
        
        try {
          const result = await axios.post<unknown, ItemResponse>(url, body);
          return { data: result };
        } catch (error) {
          const { response: { data: { title, detail, status } } } = error as ResponseError
          return { error: { error: detail, status: status, data: title } };
        }
      },
    }),
    updateImage: build.mutation<ItemResponse, Partial<{
      id: string,
      file: File,
      templateType: TemplateType,
      order: number,
    }>>({
      // invalidatesTags: ['MAP'],
      invalidatesTags: ['TEMPLATES'],
      queryFn: async ({
        id,
        file,
        templateType,
        order,
      }) => {
        const body = new FormData();

        if (file) {
          body.append('file', file);
        }
        body.append('order', `${order}`)

        const url = `${REACT_APP_BACKEND}/template/${templateType}/${id}`;

        try {
          const result = await axios.patch<unknown, ItemResponse>(url, body);
          return { data: result };
        } catch (error) {
          const { response: { data: { title, detail, status } } } = error as ResponseError
          return { error: { error: detail, status: status, data: title } };
        }
      },
    }),
    deleteImage: build.mutation<ItemResponse, {
      id: string,
      templateType: TemplateType,
    }>({
      invalidatesTags: ['TEMPLATES'],
      query: ({ id, templateType }) => ({
        method: 'DELETE',
        url: `/template/${templateType}/${id}`,
      })
    }),
})
});

export const { useLoadItemsQuery, useUploadImageMutation, useUpdateImageMutation, useDeleteImageMutation } = extendedApi 