import axios from "axios";
import { REACT_APP_BACKEND, emptySplitApi } from "../../../store/api"
import { LangType } from "../../layouts/components/locale";


export interface ItemResponse {
  key: string;
  cdbUrl: string;
}


const extendedApi = emptySplitApi.injectEndpoints({

  endpoints: (build) => ({
    loadDesignItems: build.query<ItemResponse[], { lang: LangType }>({
      query: ({ lang }) => {
        return { url: `/design?locale=${lang}` }
      },
      providesTags: ['DESIGN'],
    }),
    uploadImage: build.mutation<ItemResponse, {
      lang: LangType
      id: string,
      file: File,
    }>({
      // invalidatesTags: ['MAP'],
      invalidatesTags: ['DESIGN'],
      queryFn: async ({
        id,
        lang,
        file,
      }) => {
        const body = new FormData();

        body.append('file', file);
        body.append('key', id)
        body.append('locale', lang)

        const url = `${REACT_APP_BACKEND}/design`;
        const result = await axios.post<unknown, ItemResponse>(url, body);

        return { data: result };   
      },
    }),
    updateImageDesign: build.mutation<ItemResponse, {
      lang: LangType
      id: string,
      file: File,
    }>({
      // invalidatesTags: ['MAP'],
      invalidatesTags: ['DESIGN'],
      queryFn: async ({
        id,
        lang,
        file,
      }) => {
        const body = new FormData();

        body.append('file', file);
        body.append('locale', lang)

        const url = `${REACT_APP_BACKEND}/design/${id}`;
        const result = await axios.put<unknown, ItemResponse>(url, body);

        return { data: result };   
      },
    }),
})
});

export const { useLoadDesignItemsQuery, useUploadImageMutation, useUpdateImageDesignMutation } = extendedApi 