import cn from 'classnames';
import css from '../styles/welcome.module.css';

// import logoImg from '../assets/img/logo.svg';
// import chibiImg from '../assets/img/chibi.svg';

// import uniqRuImg from '../assets/img/uniq/ru.svg';
// import uniqEnImg from '../assets/img/uniq/en.svg';
// import uniqPtImg from '../assets/img/uniq/pt.svg';
// import uniqTrImg from '../assets/img/uniq/tr.svg';

import { ButtonLink } from '../../select-items/components/Button';
import { useEffect, useMemo } from 'react';
import Tape from '../../layouts/components/Tape';
import { useTranslation } from 'react-i18next';
import { YM_EVENTS, ymEvent } from '../../metrica/ym';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';
import ImageChangeWrap from '../../admin-actions/components/ImageChangeWrap';
import { useLoadDesignItemsQuery } from '../../admin-actions/api/designimg-item-api';

interface WelcomeProps {
  readonly hide: boolean,
}


export default function Welcome({ hide }: WelcomeProps) {
  const { i18n } = useTranslation();

  const { data: images, isLoading } = useLoadDesignItemsQuery({ lang: i18n.language });

  const imageList = useMemo(() => {
    if (!images) return {};
    return images.reduce((acc, item) => {
      acc[item.key] = item.cdbUrl;
      return acc;
    }, {} as Record<string, string>);
  }, [images]);


  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)

    appHeight();
    
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  // const uniqImg = uniqsImg[i18n.language as 'en' | 'ru' | 'pt' | 'tr'];

  const content = !hide && (
    <>
      <ImageChangeWrap key="chibi" id="chibi" lang={i18n.language}>
        <img className={css.chibi} src={imageList?.['chibi']} alt="chibi" />
      </ImageChangeWrap>
      <ImageChangeWrap key="uniq" id="uniq" lang={i18n.language}>
        <img className={css.uniq} src={imageList?.['uniq']} alt="uniq" />
      </ImageChangeWrap>
      <ButtonLink
        onClick={() => ymEvent(YM_EVENTS.WELCOME_CREATE_BUTTON)}
        href="#select-bg" 
        className={css.create} 
        preset="yellow">
          <TranslateWrap>
            welcome:create
          </TranslateWrap>
        </ButtonLink>
    </>
  );

  if (isLoading) {
    return null;
  }

  return (<div className={cn(css.welcome, { [css.welcome_hide]: hide })}>
    <ImageChangeWrap key="6years" id="6years" lang={i18n.language}>
      <img className={cn(css.logo, { [css.logo_hide]: hide })} src={imageList?.['6years']} alt="6 years" />
    </ImageChangeWrap>
    {content}
    <Tape id="editor_start" />
  </div>);
}