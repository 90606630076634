import { useCallback } from "react";
import { Selection, select } from "d3-selection";
import isTouchDevice from 'is-touch-device';
import { Size } from "../../react-d3-wrap/types";
import { DataItem } from "../types";
import { DragBehavior, SubjectPosition } from "d3-drag";

import rotateImg from '../assets/rotate.svg';
import resizeImg from '../assets/resize.svg';
import removImg from '../assets/remove.svg';
import classNames from "classnames";


type DragType = DragBehavior<SVGImageElement, DataItem, DataItem | SubjectPosition>;

interface UseDrawProps {
  size: Size,
  scale: DragType,
  rotate: DragType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touchRotate: (select: Selection<any, DataItem, SVGSVGElement, unknown>) => void,
  // TODO Fix it touchRotate: (select: Selection<SVGImageElement, DataItem, SVGSVGElement, unknown>) => void,
  drag: DragType,
  onSelect: (d: DataItem | null) => void,
  onRemove: (d: DataItem['id']) => void,
  selectedId?: DataItem['id'],
  bgTrans?: boolean,
}



export default function useDraw({ scale, rotate, touchRotate, drag, onSelect, onRemove, selectedId, bgTrans }: UseDrawProps) {
  const draw = useCallback((layers: DataItem[], size: Size, svg: SVGSVGElement) => {
    
    const svgSelection = select(svg);

    const isTouch = isTouchDevice();

    svgSelection
      // .attr("width", size.width)
      // .attr("height", size.height)
      .selectAll<SVGGElement, DataItem>("g")
      .data<DataItem>(layers, (d: DataItem) => d.id)
      .join(
        (enter) => {
          const g = enter.append("g");
          g.attr("id", d => d.id);

          g.append("text")
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            .attr("class", "phrase")
            // .attr("text-anchor", "end")
            // .text(d => d?.text || '')
            .style('display', d => (!d.text) ? 'none' : 'block')
            // .style('font-size', () => 20)
            .on('click', (_, d) => {
              if (d.isDragable) {
                onSelect(d);
              }
            })
            .style("transform", (d) => `rotate(${d.rotate}deg)`)
            .call(touchRotate)
            .selectAll('tspan').data(d => (d?.text?.split("\n") || []).map(text => ({ text, x: d.x })))
            .enter()
              .append('tspan')
              // .attr("textLength", 250)
              .attr("lengthAdjust", "spacingAndGlyphs")
              .attr('dy', '1em')
              .attr('x', d => d.x)
              .text(function(d) {
                return d.text;
              })
            
          
          // createText.selectAll('tspan').call((selection: any) => {
          //   selection.data(d => {
          //       const text = d.text;
          //       if (!text) {
          //         return;
          //       }
          //       text.split('\n').append('tspan').text((d: DataItem) => d.text);
          //     }
          // );

          // createText
          //   .append('tspan')
          //   .attr('class', 'phrase')
          //   .text(d => d?.text || '')


          g.append("image")
            .style('display', d => !d.text ? 'block' : 'none')
            .attr("class", (d) => {
              return classNames('pic', { ['pic_selected']: selectedId === d.id, ['pic_loading']: d.isLoading })
            })
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            .attr("width", (d) => d.isFullSizeBg? size.width : d.width)
            .attr("height", (d) => d.isFullSizeBg? size.height : d.height)
            .attr("preserveAspectRatio", "none")
            .attr("href", (d) => d.src)
            .style("transform", (d) => `translate(${d.x}px ${d.y}px)`)
            .style("transform", (d) => `rotate(${d.rotate}deg)`)
            .attr('fetchpriority', 'high')
            .style("opacity", d => {
              if (d.id === 'bg' && bgTrans) {
              return 0.5;
              }
              return 1;
            })
            .on('click', (_, d) => {
              if (d.isDragable) {
                onSelect(d);
              }

              if (d.id === 'bg') {
                onSelect(null);
              }
            })
            .call(touchRotate)
  
          g
            .append("image")
            .style('display', d => !isTouch && d.id === selectedId && d.isDragable ? 'block' : 'none')
            .attr("class", "rotate")
            .attr("href", rotateImg)
            .attr("x", (d) => d.x + d.width / 2 - (34 / 2))
            .attr("y", (d) => d.y + d.height)
            .attr("width", 40)
            .attr("height", 40)
            // .attr("r", 5)
            .call(rotate);
            
          g.append("image")
            .attr("class", "resize")
            .style('display', d => !isTouch && d.id === selectedId && d.isDragable ? 'block' : 'none')
            .attr("href", resizeImg)
            .attr("x", (d) => d.x + d.width)
            .attr("y", (d) => d.y + d.height)
            .attr("width", 36)
            .attr("height", 36)
            // .attr("r", 5)
            .call(scale);

          g.append("image")
            .attr("class", "removeimg")
            .style('display', d =>  d.id === selectedId && !d.unDelete ? 'block' : 'none')
            .attr("href", removImg)
            .attr("x", (d) => d.x + d.width / 2 - 20)
            .attr("y", (d) => d.y - 50)
            .attr("width", 60)
            .attr("height", 42)
            // .attr("r", 5)
            .on('click', (_, d) => {
              if (!d.unDelete) {
                onRemove(d.id);
              }
            })

          g.append("image")

            return g;
        },
        (update) => {
          update
            .style("transform", (d) => `translate(${d.x}px ${d.y}px)`)
            .attr("id", d => d.id)
            .attr("class", (d) => {
              return selectedId === d.id ? "layer layer_selected" :  "layer";
            })
  
          const updatePhrase = update.select<SVGTextElement>(".phrase")
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            // .style('font-size', d => d.height)

          updatePhrase.each(function(d) {
            select(this).selectAll('tspan').attr('x', d.x);
          });

          const updatePic = update
            // THIS IS 
            .select<SVGImageElement>(".pic")
            .call(touchRotate)
            .style('display', d => !d.text ? 'block' : 'none')
            .style("opacity", d => {
              if (d.id === 'bg' && bgTrans) {
              return 0.5;
              }
              return 1;
            })

         
          updatePic.attr("class", (d) => {
              return classNames('pic', { ['pic_selected']: selectedId === d.id, ['pic_loading']: d.isLoading })
            })
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            // .attr("width", (d) => d.width)
            // .attr("height", (d) => d.height)
            .attr("width", (d) => d.isFullSizeBg? size.width : d.width)
            .attr("height", (d) =>  d.isFullSizeBg ? size.height : d.height)
            .style("transform", (d) => `rotate(${d.rotate}rad)`)
            .filter(d => {
              return d.src !== updatePic?.node()?.href.baseVal 
            })
            .attr("href", (d) => {
              return d.src
            })
    
          update
            .select<SVGImageElement>(".resize")
            .style('display', d => !isTouch && d.id === selectedId && d.isDragable ? 'block' : 'none')
            .attr("x", (d) => d.x + d.width)
            .attr("y", (d) => d.y + d.height)
            .attr("r", 5)
            .call(scale);
            
          update
            .select("text")
            .attr("x", (d) => d.x)
            .attr("y", (d) => d.y)
            .style("transform", (d) => `rotate(${d.rotate}deg)`)
            .call(touchRotate)

            // .text(d => `${d.scale} w: ${d.width} ${d.x} ${d.y}`)
  
  
          update
            .select<SVGImageElement>(".rotate")
            // .style('display', d => d.isDragable ? 'block' : 'none')
            .style('display', d => !isTouch && d.id === selectedId && d.isDragable ? 'block' : 'none')
            // .attr("x", (d) => d.x)
            .attr("x", (d) => d.x + d.width / 2 - (34 / 2))
            .attr("y", (d) => d.y + d.height)
            .attr("r", 5)
            // .call(rotate);
            // .call(touchRotate)

          update
            .select<SVGImageElement>(".removeimg")
            // .style('display', d => d.isDragable ? 'block' : 'none')
            .style('display', d => d.id === selectedId && d.isDragable && !d.unDelete ? 'block' : 'none')
            // .attr("x", (d) => d.x)
            .attr("x", (d) => d.x + d.width / 2 - 20)
            .attr("y", (d) => d.y - 50)
            .attr("r", 5)
            .on('click', (_, d) => {
              if (!d.unDelete) {
                onRemove(d.id);
              }
            })
            // .call(rotate);
            // .call(touchRotate)


          return update;
        },
        (exit) => {
          exit.remove();
        }
      );

  
    // svgSelection
    //   .selectAll<SVGImageElement, DataItem>(".removeglobal")
    //   .data([selectedId])
    //   .join('.removeglobal')
    //     .attr('opacity', (d) => {
    //       console.log(d);
    //       return false;
    //     })
    //     .attr("x", size.width - 60 - 10)
    //     .attr("y", size.height - 34 - 17)
    //     .attr("class", "removeglobal")
    //     .attr("href", removImg)
    //     .attr("width", 60)
    //     .attr("height", 42)
    //     .attr("fill", "#363636")
    //     .on('touchend', () => {
    //       if (!selectedId) {
    //         return;
    //       }
    //       onRemove(selectedId);
    //     });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drag, selectedId, onSelect, bgTrans]);

  return { draw };
}