import css from '../styles/final-screen.module.css';
import Header from './header';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

interface FinalScreenProps {
  children: React.ReactNode;
}

export default function FinalScreen({ children }: FinalScreenProps) {
  return (<div className={css.finalScreen}>
    <Header className={css.header} id="final_screen"><TranslateWrap>editor:header-position</TranslateWrap></Header>
    {children}
  </div>);
}