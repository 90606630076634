import { D3DragEvent, drag } from 'd3-drag';

type DragEvenet<DATA> = D3DragEvent<SVGGElement, DATA, SVGElement>;

// function dragstarted(this: SVGGElement, event: DragEvenet, d: DataItem) {
//   select(this).raise().style("outline", "1px solid blue");
//   if (this.parent) {
//     select(this.parent).raise();
//   }
// }

// function dragended(event: Drag, d) {
//   select(this).style("outline", "1px solid gray");
// }

type DragHandler<DATA> = (event: DragEvenet<DATA>,  d: DATA) => void;
export function initDrag<DATA>(
  onDragStart?: DragHandler<DATA>,
  onDrag?: DragHandler<DATA>,
  onDragEnd?: DragHandler<DATA>,
) {
  const dragInstance = drag<SVGImageElement, DATA>()
  if (onDragStart) {
    dragInstance.on("start", onDragStart)
  }

  if (onDrag) {
    dragInstance.on("drag", onDrag)
  }

  if (onDragEnd) {
    dragInstance.on("end", onDragEnd);
  }

  return dragInstance;
}
