import { emptySplitApi } from "../../../store/api"
import { TemplateResponse } from "./types"


const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getBgs: build.query<TemplateResponse, { locale: string  }>({
      providesTags: ['TEMPLATES'],
      query: ({ locale }) => {
        return `template?locale=${locale === 'pt' ? 'pt' : locale}`
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetBgsQuery } = extendedApi 