import { useDispatch, useSelector } from "react-redux";
import { TemplateData } from "../../editor/api/types";
import { DataItemNew } from "../../editor/types";
import { Size } from "../../react-d3-wrap/types";
import { LayerActions, layersFaceSelect } from "../../editor/store/layers";
import { YM_EVENTS, ymEvent } from "../../metrica/ym";

interface useAddRandomProps {
  stickers: TemplateData[],
  pharases: TemplateData[],
  characters: TemplateData[],
  onAddLayer: (id: DataItemNew) => void;
  size: Size;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function useAddRandom({ size, onAddLayer, stickers, pharases, characters }: useAddRandomProps) {
  // const dispatch = useDispatch();

  const layersForReset = useSelector(layersFaceSelect);

  const moveToEnd = () => {
    const el = document.getElementById('final_screen');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const dispatch = useDispatch();


    // TODO fix duplicate
    const onAddPhrase = async (p: TemplateData) => {
      const img = new Image();
      img.onload = () => {
        const ratio = img.width / img.height;
  
        const width = size.width;
        const height = width / ratio;
  
        onAddLayer({
          id: `phrase_${performance.now()}`,
          src: p.cdnUrl,
          width: width,
          height: height,
          x: 0,
          y: size.height - height,
          rotate: 0,
          isDragable: true,
          disableRotate: true,
          stayInViewport: true,
          layerType: 'text',
        });
  
      };
  
      img.src = p.cdnUrl;
    }

  const onRandom = () => {

    ymEvent(YM_EVENTS.EDITOR_RANDOM_CHOICE);

    dispatch(LayerActions.setLayers(layersForReset));

    for (let i=0; i< Math.ceil(Math.random() * 4); i++) {
      const sticker: TemplateData = stickers[Math.floor(Math.random() * stickers.length)];
      setTimeout(() => {
        onAddLayer({
          id: `sticker_${performance.now()}`,
          src: sticker.cdnUrl,
          width: 140,
          height: 140,
          x: Math.round(Math.random() * (size.width - 154)),
          y: Math.round(Math.random() * (size.height - 154)),
          rotate: 0,
          isDragable: true,
          minimumSize: 100,
          layerType: 'sticker',
        });
      }, 10);
    }
    const phrase: TemplateData = pharases[Math.floor(Math.random() * pharases.length)];

    onAddPhrase(phrase)

    moveToEnd();
  }

  return { onRandom };
}
