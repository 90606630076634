// import ruImg from '../assets/langs/ru.svg';
import enImg from '../assets/langs/en.svg';
// import trImg from '../assets/langs/tr.svg';
import brImg from '../assets/langs/br.svg'

import css from '../styles/locale.module.css';
import { useTranslation } from 'react-i18next';

// const langs = ['ru', 'en', 'tr', 'pt'];

const langs = ['en', 'pt'];

export type LangType =  typeof langs[number];

export default function Locale() {

  const imgs: Record<(typeof langs)[number], string> = {
    // ru: ruImg,
    en: enImg,
    // tr: trImg,
    pt: brImg,
  };

  const { i18n } = useTranslation();


  const lang = i18n.language;

  const onChangeLang = (lang: string) => () => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  }

  return (<div className={css.locale}>
    <div className={css.current}>
      <img src={imgs[lang]} alt={lang} /> 
    </div>
    {langs.map((lang) => (
      <button key={lang} className={css.item} onClick={onChangeLang(lang)}>
        <img src={imgs[lang]} alt={lang} />
        <div className={css.item__name}>{lang}</div>
      </button> 
    ))}
  </div>)
}