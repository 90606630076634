import axios from "axios";
import { REACT_APP_BACKEND, emptySplitApi } from "../../../store/api"
import { LangType } from "../../layouts/components/locale";

type DesicionsType = 'IN_PROGRESS' | 'ACCEPT' | 'FAILED' | 'DECLINED';

interface ModerationResponseUpload {
  data: { uuid: string };
}

interface ResponseResult {
  data: {
    decision: DesicionsType,
  }
}

interface UploadResult {
  data: {
    uuid: string,
    cdnUrl: string,
    moderationDecision: DesicionsType,
    previewUrl: string,
  }
}

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    uploadAttachment: build.mutation<ResponseResult['data'], {
      file: File,
    }>({
      // invalidatesTags: ['MAP'],
      queryFn: async ({
        file,
      }) => {
        const body = new FormData();

        let desition: DesicionsType | null = null;

        body.append('file', file);

        try {
          const url = `${REACT_APP_BACKEND}/moderation`;
    
          const result = await axios.post<unknown, ModerationResponseUpload>(url, body);

          let resultModeration: ResponseResult = await axios.get(`${REACT_APP_BACKEND}/moderation/${result.data.uuid}`);

          while (resultModeration.data.decision === 'IN_PROGRESS') {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            resultModeration = await axios.get(`${REACT_APP_BACKEND}/moderation/${result.data.uuid}`);
          }

          desition = resultModeration.data.decision;
        } catch(e) {
          return { 
            error: {
              status: 500,
              data: { decision: 'FAILED' }
            }
          }
        }

        console.log('result2', desition);


        if (desition === 'ACCEPT') {
          return { data: { decision: 'ACCEPT' } };   
        }

        return { error: {
          status: 403,
          data: { decision: 'FAILED' }
        } };
   
      },
    }),
    uploadResult: build.mutation<UploadResult['data'], {
      file: File, lang: LangType,
    }>({
      // invalidatesTags: ['MAP'],
      queryFn: async ({
        file,
        lang
      }) => {
        const body = new FormData();

        let resultModerationUpload: UploadResult['data'] | null = null;

        body.append('file', file);
        // /v1/contents/{contentId}/versions/{versionId}/attachments/{platform}

        try {
          const url = `${REACT_APP_BACKEND}/gift-card?locale=${lang}`;
    
          const result = await axios.post<unknown, UploadResult>(url, body);

          let resultModeration: UploadResult = await axios.get(`${REACT_APP_BACKEND}/gift-card/${result.data.uuid}`);

          while (resultModeration.data.moderationDecision === 'IN_PROGRESS') {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            resultModeration = await axios.get(`${REACT_APP_BACKEND}/gift-card/${result.data.uuid}`);
          }

          resultModerationUpload = resultModeration.data;

        } catch(e) {
          return { 
            error: {
              status: 500,
              data: { decision: 'FAILED' },
              uuid: '',
              cdnUrl: '',
            }
          }
        }

        console.log('result2', resultModerationUpload);

        if (resultModerationUpload.moderationDecision === 'FAILED') {
          return { error: {
            status: 403,
            data: { decision: 'FAILED' },
            uuid: '',
            cdnUrl: '',
          } };
        }

        return { data: resultModerationUpload };       
      },
    }),
  loadStory: build.query<UploadResult['data'], { id: string, lang: LangType }>({
    query: ({ id, lang }) => ({ url: `/gift-card/${id}?locale=${lang}` }),
  }),
})
});

export const { useUploadAttachmentMutation, useUploadResultMutation, useLoadStoryQuery, useLazyLoadStoryQuery } = extendedApi 