import css from '../styles/Layout.module.css';
// import YearsImg from '../assets/6years.svg';
// import StandoffImg from '../assets/standoff.svg';
import Footer from './footer';
import Locale from './locale';

interface LayoutProps {
  children?: React.ReactNode;
}

export default function LayoutView({ children }: LayoutProps) {
  return (
    <div className={css.layout}>
      <Locale />
      <div className={css.bg} />
      {/* <header className={css.header}>
        <div className={css.logo}>
          <img src={YearsImg} alt="6 years" />
          <img src={StandoffImg} alt="Standoff 2" />
        </div>
        <div className={css.label}>
          Поделись с друзьями
          авторской открыткой
        </div>
      </header> */}
      <main className={css.main}>
        {children}
      </main>
      <Footer />
    </div>
  );
}