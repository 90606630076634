export default function imageRenderToCanvas (
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  coords: { x: number, y: number },  
  size: { width: number, height: number },
  angle: number,
  scale: number
) {

  const { x, y } = coords;
  const { width, height } = size;

  const centerX = width / 2.0;
  const centerY = height / 2.0;

  // ctx.clearRect(x, y, 1000, 1000);
  // save context's current transform state
  ctx.save();

  // move context's origin to image position
  ctx.translate(x + width / 2.0, y + height / 2.0);

  // // apply transformations
  ctx.rotate(angle);
  ctx.scale(scale, scale);

  // // draw image centered on its position
  ctx.drawImage(image, -centerX, -centerY, width, height);

  ctx.restore();
}