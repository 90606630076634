interface ShareImageProps {
  url: string,
}

export default function shareImage({ url }: ShareImageProps) {
  if (!navigator.canShare) {
    return;
  }

  try {
    navigator.share({
      title: "STANDOFF 2",
      text: "#7yearsSO2",
      url: url,
    });
  } catch (e) {
    console.error(e);
  }
}