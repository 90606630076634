import { Selection } from "d3-selection";
import { DataItem } from "../types";
import { useDispatch } from "react-redux";
import { LayerActions } from "../store/layers";
import { mean } from "d3-array";
import { useCallback, useRef } from "react";
import { SelectActions } from "../store/selected";

type Points = [number, number][];


interface DragData {
  pointerangle: number;
  pointerposition: [number, number] | null;
  pointerdistance: number;
  angle: number;
  offsetDrag: { x: number, y: number };
}



function dragStartPrepare(t: Points, d: DataItem) {
  const pointerangle =
    t.length > 1 && Math.atan2(t[1][1] - t[0][1], t[1][0] - t[0][0]); // (A)

  const pointerposition = [mean(t, (d) => d[0]), mean(t, (d) => d[1])]; // (B)

  const pointerdistance =
    t.length > 1 && Math.hypot(t[1][1] - t[0][1], t[1][0] - t[0][0]); // (C)

  const offsetDrag = { x: t[0][0] - d.x, y: t[0][1] - d.y };

  const angle = 0;

  return { pointerangle, pointerposition, pointerdistance, offsetDrag, angle };
}

function dragPreProcess(dragData: DragData, t: Points, d: DataItem) {
  
  if (!dragData.pointerposition) return;

  const position = [d.x, d.y];
  let width = d.width;
  let height = d.height;
  // (A)
  position[0] -= dragData.pointerposition[0];
  position[1] -= dragData.pointerposition[1];
  dragData.pointerposition = [mean(t, (d) => d[0]) as number, mean(t, (d) => d[1]) as number];
  position[0] += dragData.pointerposition[0];
  position[1] += dragData.pointerposition[1];

  if (t.length > 1) {
    // (B)
    dragData.angle -= dragData.pointerangle;
    dragData.pointerangle = Math.atan2(t[1][1] - t[0][1], t[1][0] - t[0][0]);
    dragData.angle += dragData.pointerangle;
    // (C)
    
    const widthBefore = width;
    const heightBefore = height;


    width /= dragData.pointerdistance;
    height /= dragData.pointerdistance;

    dragData.pointerdistance = Math.hypot(t[1][1] - t[0][1], t[1][0] - t[0][0]);
    width *= dragData.pointerdistance;
    height *= dragData.pointerdistance;

    // console.log('wdith===', width, width < widthBefore);
    if (d.minimumSize && width < d.minimumSize && width < widthBefore) {
      width = widthBefore;
      height = heightBefore;
    }

    position[0] -= (width - widthBefore) / 2;
    position[1] -= (height - heightBefore) / 2;

    return {
      position: position,
      angle: dragData.angle,
      width,
      height,
    };
  }
}


function limitInViewPort(d: DataItem, points: { x: number, y: number }, screen: { width: number, height: number }) {
  let changes = {
    ...points,
  }

  if (d.stayInViewport) {
    changes = {
      x: Math.min(Math.max(0, points.x), screen.width - d.width),
      y: Math.min(Math.max(0, points.y), screen.height - d.height),  
    }

    // console.log('changes--', Math.min(Math.max(0, points.x), screen.width - d.width));
  }

  return changes;
}

  function extractPointsFromTouch(event: TouchEvent) {
    const t: [number, number][] = Array.prototype.map.call(event.targetTouches, (t: Touch): [number, number] => [
      t.screenX,
      t.screenY,
    ]) as [number, number][];

    return t;
  }

  function extractPointsFromMouse(event: MouseEvent) {
    const t: [number, number][] = [[event.screenX, event.screenY]];
    return t;
  }

  function extractPoints(event: TouchEvent | MouseEvent) {
    return ('touches' in event) ? extractPointsFromTouch(event) : extractPointsFromMouse(event);
  }


  export function useTouch(screen: { width: number, height: number }, selectedId?: DataItem['id']) {
    const dispatch = useDispatch();

    const dragData = useRef<DragData>({
      pointerangle: 0, // (A)
      pointerposition: null, // (B)
      pointerdistance: 0, // (C)
      angle: 0,
      offsetDrag: { x: 0, y: 0 },
    });

    const rotate = useCallback((selection: Selection<SVGImageElement, DataItem, SVGSVGElement, unknown>) => {

      selection
      .on("mousedown touchstart", function (event: TouchEvent, d) {
        if (!d.isDragable) return false;

        event.preventDefault();

        const t: [number, number][] = ('touches' in event) ? extractPointsFromTouch(event) : extractPointsFromMouse(event);

        const data = dragStartPrepare(t, d);

        if (data) {
          dragData.current = data as unknown as DragData;
        }

        dispatch(SelectActions.setSelected(d));
        dispatch(LayerActions.toFront({ id: d.id }));
      })
      .on("mouseup touchend", function (_, d) {
        if (!d.isDragable) return false;
        dragData.current.pointerposition = null; // signals mouse up for (D) and (E)
        dispatch(LayerActions.postDrag());
      })
      .on("mousemove touchmove", function (event: TouchEvent | MouseEvent, d) {
        if (!d.isDragable) return false;
        if (d.id !== selectedId) return;


        if (!dragData.current.pointerposition) return;

        if (!d.stayInViewport && 'touches' in event && event.targetTouches.length > 1) {
          const t: [number, number][] = extractPoints(event);
  
          const dragResult = dragPreProcess(
            dragData.current,
            t,
            d
          );

          if (dragResult) {
            const { width, height, position, angle } = dragResult;

            dispatch(LayerActions.updateLayer({
              id: d.id,
              changes: {
                width,
                height,
                rotate: angle,
                x: position[0],
                y: position[1], 
              }
            }))
          }
        }
  
        if (event instanceof MouseEvent || event.targetTouches.length === 1) {
          const t: [number, number][] = extractPoints(event);

          const coords = {
            x: t[0][0] - dragData.current.offsetDrag.x,
            y: t[0][1] - dragData.current.offsetDrag.y,
          }

          const changes = limitInViewPort(d, coords, screen);

          dispatch(LayerActions.updateLayer({
            id: d.id,
            changes,
          }));
        }
      });
    }, [dispatch, screen, selectedId]);
    
    return { rotate };
  }