import { Button } from '../../select-items/components/Button';
import css from '../styles/editor-header.module.css';

import stickerImg from '../assets/icons/sticker.svg';
import textImg from '../assets/icons/text.svg';
import characterImg from '../assets/icons/character.svg';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

interface EditorHeaderProps {
  onRandom?: () => void;
}

export default function EditorHeader({ onRandom }: EditorHeaderProps) {
  return (
    <div className={css.editorHeader}>
      <h1 className={css.header} id="select_photo">
        <TranslateWrap>editor:draw-your-picture</TranslateWrap>
      </h1>
      <div className={css.controls}>
        <a href="#select_stickers" className={css.control__item}>
          <img src={stickerImg} alt="stickers" />
        </a>
        <a href="#select_characters" className={css.control__item}>
          <img src={characterImg} alt="characters" />
        </a>
        <a href="#select_phrases" className={css.control__item}>
          <img src={textImg} alt="phrases" />
        </a>
      </div>
      <Button preset="white" className={css.random} onClick={onRandom}>
        <TranslateWrap>editor:random-choice</TranslateWrap>
      </Button>
    </div>
  );
}