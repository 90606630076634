import logo from '../assets/axlebolt.svg';
import tiktokImg from '../assets/social/tiktok.svg';
import youtubeImg from '../assets/social/youtube.svg';
import discordImg from '../assets/social/discord.svg';
import vkImg from '../assets/social/vk.svg';

import css from '../styles/Footer.module.css';
import { useTranslation } from 'react-i18next';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';


const SOCIALS = [
  {
    id: '1',
    src: tiktokImg,
  },
  {
    id: '2',
    src: youtubeImg,
  },
  {
    id: '3',
    src: discordImg,
  },
  {
    id: '4',
    src: vkImg,
  },
]

export default function Footer() {
  const { i18n } = useTranslation();

  const lang = i18n.language === 'ru' ? 'ru' : 'en';

  return (
    <footer className={css.footer}>
    <img className={css.logo} src={logo} alt="logo" />

    <div className={css.footer__rect}>
      <div className={css.social}>
        {SOCIALS.map(s => (
          <img className={css.social__icon} key={s.id} src={s.src} alt="social" />
        ))}
      </div>
      <a className={css.link} href={`/docs/terms_${lang}.html`}>
        <TranslateWrap>welcome:public-offer</TranslateWrap>
      </a>
      <a className={css.link} href={`/docs/privacy_policy_${lang}.html`}>
        <TranslateWrap>welcome:privacy-security</TranslateWrap>
      </a>
      <div>
        v0.17.3.c763177d
      </div>

      <div className={css.footer__rect__recaptcha}>
       This site is protected by reCAPTCHA Enterprise and
        the Google Privacy Policy and Terms of Service apply.
      </div>
    </div>
  </footer>
  );
}