import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { DataItem } from '../types'
import { RootState } from '../../../store/store'


const layerAdapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (book: DataItem) => book.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.order - b.order,
})

export const layerSlice = createSlice({
  name: 'layers',
  initialState: layerAdapter.getInitialState({
  }),
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    createLayer: (state, action: PayloadAction<Omit<DataItem, 'order'> & { order?: number }>) => {

      const layerData = action.payload;

      const layerWithOrder = {
        ...layerData,
        order: layerData.order || 1000 + state.ids.length * 1000,
      };

      layerAdapter.addOne(state, { ...action, payload: layerWithOrder });
    },
    setLayers: layerAdapter.setAll,
    removeBySrc: (state, action: PayloadAction<{ src: DataItem['src'] }>) => {
      const { src } = action.payload;

      const layer = Object.values(state.entities).find(l => l.src === src);
      if (layer) {
        layerAdapter.removeOne(state, layer.id);
      }
    },
    removeLayer: layerAdapter.removeOne,
    updateLayer: (state, action: PayloadAction<{ id: DataItem['id'], changes: Partial<DataItem> }>) => {
      const { id } = action.payload;
      state.entities[id] = {
        ...state.entities[id],
        ...action.payload.changes,
      }

      const { controlOf } = state.entities[id];
      if (controlOf) {
        const { x, y, rotate, width, height } = state.entities[id];
        state.entities[controlOf] = {
          ...state.entities[controlOf],
          x,
          y,
          rotate,
          width,
          height,
          // ...action.payload.changes,
          // id: state.entities[id].id,
        }
      }

    },
    postDrag: (state) => {
      state.ids.sort((a, b) => {

        const { layerType: layerTypeA } = state.entities[a];

        const { layerType: layerTypeB } = state.entities[b];

        if (layerTypeA === 'logo') {
          return 1;
        }

        if (layerTypeB === 'logo') {
          return -1;
        }
        
        if (layerTypeA === 'text') {
          return 1;
        }
        if (layerTypeB === 'text') {
          return -1;
        }


        // logo
        return state.entities[a].order - state.entities[b].order
      }); 
    },
    //layerAdapter.updateOne,
    toFront: (state, action: PayloadAction<{ id: DataItem['id'] }>) => {
      const { id } = action.payload;
      const firstOrderId = state.ids[state.ids.length - 1];
      if (firstOrderId && state.entities[id]) {
        const firstItemOrder = state.entities[firstOrderId].order;
        state.entities[id].order = firstItemOrder + 1;
      }

      state.ids.sort((a, b) => {
        return state.entities[a].order - state.entities[b].order
      }); 
      return state;
    }
  },
})

// export const layersSelect = (state: RootState) => layerAdapter.setAll(state.layers);

export const LayerActions = layerSlice.actions;

export const layersSelectors = layerAdapter.getSelectors<RootState>(
  (state) => state.layers
);

export const layersFaceSelect = (state: RootState) => 
  layersSelectors.selectAll(state).filter(l => (['bg', 'child', 'child-control', 'logo'].includes(l.id)));

export const layersWithExtrasSelect = (state: RootState) => 
  layersSelectors.selectAll(state).map(l => ({
    ...l,
    isSelected: state.selected.selectedId === l.id,
  }));


export const selectItemType = (layerType: DataItem['layerType']) =>  (state: RootState) => 
  layersSelectors.selectAll(state).filter(l => l.layerType === layerType).map(l => l.src !== 'none' ? l.src : `${l.text}`);



export const selectBg = (state: RootState) => layersSelectors.selectById(state, 'bg');