import { ModalChildrenProps } from '../../layouts/types';
import { Button } from '../../select-items/components/Button';
import css from '../styles/moderation-pending.module.css';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

export default function ModerationFailed({ onClose }: ModalChildrenProps) {
  return (<div className={css.pending}>
    <div><TranslateWrap>editor:moderation-failed</TranslateWrap></div>
    <Button preset="yellow" onClick={onClose}><TranslateWrap>editor:close</TranslateWrap></Button>
  </div>);
}