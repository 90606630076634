import { useContext } from "react";
import { useTranslation } from "react-i18next";
import css from '../styles/TranslateWrap.module.css';
import { AdminContext } from "../context/AdminContext";
import { ADMIN_ENABLED, LOCALIZER_DOMAIN, LOCALIZER_PROJECT_ID } from "../const";

interface TranslateWrapProps {
  children: string,
}

export default function TranslateWrap({ children: tKey }: TranslateWrapProps) {
  const { t } = useTranslation();

  const { isAdmin  } = useContext(AdminContext)
  const projectId = LOCALIZER_PROJECT_ID;
  const localizerDomain = LOCALIZER_DOMAIN;

  const [ns, key] = tKey.split(':');

  if (isAdmin && ADMIN_ENABLED) {
    return <div className={css.TranslateWrap_higlight}>
      <a href={`${localizerDomain}/project-link/${projectId}/${ns}/${key}`} target="blank" className={css.KeyLink}>
        {tKey}
      </a>
    </div>
  }

  return (<>{t(tKey)}</>);
}