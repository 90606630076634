import { ReactNode, createContext, useState } from 'react';
import css from '../styles/AdminControl.module.css';
import { ADMIN_ENABLED } from '../const';
import { useGetPusblishStatusMutation } from '../api/deploy-version';
import { Icon } from '@web-products/ui-kit';

export const AdminContext = createContext({
  isAdmin: true,
});


interface AdminContextProviderProps {
  children: ReactNode,
}

export function AdminContextProvider({ children }: AdminContextProviderProps) {
  const [adminEnambled, setAdminEnabled] = useState(false);

  const [publish, { isLoading, data }] = useGetPusblishStatusMutation();
  const onPublish = () => {
    const checkDate = new Date();

    const checkText = `${checkDate.getDate()}.${checkDate.getMonth()+1}`;

    if (prompt(`Напишите "Релиз ${checkText}"`) === `Релиз ${checkText}`) {
      publish({});
    } else {
      alert('Не верно')
    }
  }

  return (<AdminContext.Provider value={{ isAdmin: adminEnambled }}>
    {ADMIN_ENABLED && (<div className={css.adminControl}>
      <button onClick={() => setAdminEnabled(!adminEnambled)}>Toggle Admin</button>
      {adminEnambled && <button disabled={isLoading} onClick={onPublish} className={css.deployButton}>
        Deploy (danger)
        {isLoading && <Icon icon="RingLoading" />}
        {JSON.stringify(data)}
      </button>}
    </div>)}
    {children}
  </AdminContext.Provider>);
}