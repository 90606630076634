import cn from 'classnames';
import css from '../styles/modal.module.css';
import { ModalChidlren } from '../types';

interface ModalProps {
  display: boolean;
  children: ModalChidlren;
  onClose: () => void;
}

export default function Modal({ display, onClose, children }: ModalProps) {

  const content = children({ onClose });

  // useEffect(() => {
  //   debugger;
  //   setDisplayState(display);
  // }, [display, setDisplayState]);

  return (
    <div className={cn(css.modal, {
      [css.display_hide]: !display,
    })}>
      {content};
    </div>
  );
}