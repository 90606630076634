import cn from 'classnames';
import lockImg from '../assets/lock.svg';
import css from '../styles/HideContent.module.css';
import TranslateWrap from '../../admin-actions/components/TranslateWrap';

interface HideContentProps {
  children: React.ReactNode;
  hide: boolean;
}

export default function HideContent({children, hide}: HideContentProps) {
  return (<div className={cn(css.hideContent, { [css.hideContent_hide]: hide })}>
    {hide && <div className={css.hideContent__overlay} />}
    {hide && <h1 className={css.header}>
      <img src={lockImg} alt="lock" />
      <TranslateWrap>select-items:select-bg-block</TranslateWrap>
    </h1>}
    {children}
  </div>);
}