import { useDispatch } from "react-redux";
import { useUploadAttachmentMutation } from "../../editor/api/image-api";
import { LayerActions } from "../../editor/store/layers";
import { EditModeActions } from "../../editor/store/edit-mode";
import { DataItem, DataItemNew, EditModes } from "../../editor/types";

export default function useUploadModeration() {

  const dispatch = useDispatch();

  const [uploadModeration, { isLoading: isLoadingModeration, isError: isFailModeration, ...args }] = useUploadAttachmentMutation();


  // const ss = useModerationCheckPendingQuery({ uuid: data?.uuid || '' }, { skip: !data?.uuid });

  const onAddPhotSuccess = (layer: DataItemNew) => {
    dispatch(LayerActions.removeLayer('child'));
    dispatch(LayerActions.removeLayer('child-control'));

    dispatch(LayerActions.createLayer({
      ...layer,
      id: 'child',
      isDragable: false,
      order: 1000,
      unDelete: true,
    }));

    dispatch(LayerActions.createLayer({
      ...layer,
      id: 'child-control',
      order: 2500,
      src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
      isDragable: true,
      controlOf: 'child',
      className: 'move-control',
      unDelete: true,
    }));

    dispatch(EditModeActions.setEditMode(EditModes.SELECT_PHOTO));
  };


  const onAddPhoto = (file: File, layer: DataItem) => {
    uploadModeration({ file }).unwrap().then((res) => {
      if (res.decision === 'ACCEPT') {
        onAddPhotSuccess(layer);
      } else {
        throw new Error('errr');
        // alert('Изображение не прошло модерацию');
      }
    })
    .catch((e) => {
      console.error(e);
      setTimeout(() => args.reset(), 1000);
    })
  };
  
  return { onAddPhoto, isLoadingModeration, isFailModeration };
}